// export const calculateCartTotals = (items) => {
//   const subtotalPrice = items.reduce(
//     (acc, item) =>
//       acc + (item.variant?.salePrice || item.price) * item.quantity,
//     0
//   );

//   const subtotalWithoutTax = items.reduce((acc, item) => {
//     if (item.taxStatus) {
//       let salePrice = item.variant?.salePrice || item.price;
//       const gstRate =
//         Array.isArray(item.producttaxes) && item.producttaxes.length > 0
//           ? item.producttaxes[0].value
//           : 0;
//       const priceWithoutTax = salePrice / (1 + gstRate / 100);
//       return acc + priceWithoutTax * item.quantity;
//     }
//     return acc + (item.variant?.salePrice || item.price) * item.quantity;
//   }, 0);

//   const gstTotal = items.reduce((acc, item) => {
//     if (item.taxStatus) {
//       let salePrice = item.variant?.salePrice || item.price;
//       const gstRate =
//         Array.isArray(item.producttaxes) && item.producttaxes.length > 0
//           ? item.producttaxes[0].value
//           : 0;
//       const priceWithoutTax = salePrice / (1 + gstRate / 100);
//       const gstAmount = salePrice - priceWithoutTax;
//       return acc + gstAmount * item.quantity;
//     }
//     return acc;
//   }, 0);

//   const shippingPrice = 0; // Example fixed shipping price
//   const totalPrice = subtotalWithoutTax + gstTotal + shippingPrice;

//   return {
//     subtotalPrice,
//     subtotalWithoutTax,
//     gstTotal,
//     totalPrice,
//     shippingPrice,
//   };
// };

import { toast } from "react-toastify";
import { getCookie } from "./TokenUtils";
import CONSTANTS from "../constant";

const userDetailsFromCookies = getCookie("userDetails")
  ? JSON.parse(getCookie("userDetails"))
  : null;
const userId = userDetailsFromCookies ? userDetailsFromCookies.id : null;

export const calculateCartTotals = (items, couponResponse = null) => {
  const subtotalPrice = items.reduce(
    (acc, item) =>
      acc + (item.variant?.salePrice || item.price) * item.quantity,
    0
  );

  const subtotalWithoutTax = items.reduce((acc, item) => {
    if (item.taxStatus) {
      let salePrice = item.variant?.salePrice || item.price;
      const gstRate =
        Array.isArray(item.producttaxes) && item.producttaxes.length > 0
          ? item.producttaxes[0].value
          : 0;
      const priceWithoutTax = salePrice / (1 + gstRate / 100);
      return acc + priceWithoutTax * item.quantity;
    }
    return acc + (item.variant?.salePrice || item.price) * item.quantity;
  }, 0);

  const gstTotal = items.reduce((acc, item) => {
    if (item.taxStatus) {
      let salePrice = item.variant?.salePrice || item.price;
      const gstRate =
        Array.isArray(item.producttaxes) && item.producttaxes.length > 0
          ? item.producttaxes[0].value
          : 0;
      const priceWithoutTax = salePrice / (1 + gstRate / 100);
      const gstAmount = salePrice - priceWithoutTax;
      return acc + gstAmount * item.quantity;
    }
    return acc;
  }, 0);

  const shippingPrice = 0; // Example fixed shipping price

  // Apply discount if a coupon is provided
  let discount = 0;

  if (couponResponse) {
    // Check minimum spend requirement
    if (subtotalPrice < couponResponse.minSpend) {
      toast.error(
        `Minimum spend of ${CONSTANTS.CURRENCY_SYMBOL}${couponResponse.minSpend} required to apply this coupon!`
      );
      return {
        subtotalPrice,
        subtotalWithoutTax,
        gstTotal,
        totalPrice: subtotalWithoutTax + gstTotal + shippingPrice,
        shippingPrice,
        discount: 0,
      };
    }

    // if (couponResponse.userBased) {
    //   // Check if the user ID matches
    //   if (!couponResponse.userId.includes(userId)) {
    //     toast.error("This coupon is invalid or expired for your account!");
    //     return {
    //       subtotalPrice,
    //       subtotalWithoutTax,
    //       gstTotal,
    //       totalPrice: subtotalWithoutTax + gstTotal + shippingPrice,
    //       shippingPrice,
    //       discount: 0,
    //     };
    //   }
    // }

    // Apply discount based on type
    if (couponResponse.discountType === 1) {
      // Percentage discount
      discount = (subtotalPrice * couponResponse.value) / 100;
    } else if (couponResponse.discountType === 2) {
      // Fixed amount discount
      discount = couponResponse.value;
    }

    // Ensure discount does not exceed max discount amount
    if (couponResponse.maxDiscountAmount) {
      discount = Math.min(discount, couponResponse.maxDiscountAmount);
    }
  }

  // Total price after discount
  const totalPrice = subtotalWithoutTax + gstTotal + shippingPrice - discount;

  return {
    subtotalPrice,
    subtotalWithoutTax,
    gstTotal,
    totalPrice,
    shippingPrice,
    discount, // Return discount amount for display purposes
  };
};
