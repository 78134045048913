// import React, { useState } from "react";
// import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
// import Logo from "../logo/Logo";
// import "./Header.scss";
// import Profile from "../Profile/Profile";
// import { FaChartBar, FaFontAwesome } from "react-icons/fa";
// const Header = ({ onToggle }) => {
//   console.log("onToggle in Header:", typeof onToggle);
//   return (
//     <>
//       <div className="header-sec1">
//         <button onClick={onToggle}>
//           <HamburgerMenu />
//         </button>

//         <Logo />
//       </div>
//       <div className="profile">
//         {/* <FaFontAwesome
//           icon={faExclamationTriangle}
//           className="report-icon"
//           title="Report"
//         /> */}

//         <FaChartBar />

//         <Profile />
//       </div>
//     </>
//   );
// };

// export default Header;
import React, { useState, useContext } from "react";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import Logo from "../logo/Logo";
import "./Header.scss";
import Profile from "../Profile/Profile";
import { FaChartBar, FaDownload, FaFilePdf } from "react-icons/fa";
import { FaCartShopping, FaProductHunt } from "react-icons/fa6";
import CartComponent from "../Cart/Cart";
import { BiSolidReport } from "react-icons/bi";
import { CartContext } from "../../Context/CartContext";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AiFillProduct } from "react-icons/ai";

const Header = ({ onToggl, onclick, mode }) => {
  const { cartItems } = useContext(CartContext);
  const [showCart, setShowCart] = useState(false);
  const navigate = useNavigate();

  const handleCartClick = () => {
    setShowCart(!showCart); // Toggle cart visibility
    if (!showCart) {
      document.body.classList.add("cart-open"); // Add class to body to hide product container
    } else {
      document.body.classList.remove("cart-open"); // Remove class to show product container
    }
  };

  const handleCloseCart = () => {
    setShowCart(false);
    document.body.classList.remove("cart-open");
    // Close the cart
  };
  const handleReportClick = () => {
    navigate("/reports");
  };

  return (
    <>
      <div className="header-sec1">
        <button onClick={onToggl} className="ham">
          <HamburgerMenu />
        </button>

        <Logo />
      </div>
      <div className="icons">
        <div className="pro" onClick={onclick}>
          {mode == "grid" ? <FaProductHunt /> : <AiFillProduct />}
        </div>
        <div className="report">
          <BiSolidReport onClick={handleReportClick} />
        </div>
        <div className="cart">
          <div className="cart-icon-wrapper">
            <FaCartShopping onClick={handleCartClick} className="cart-icon" />
            {cartItems.length > 0 && (
              <span className="cart-count">{cartItems.length}</span>
            )}
          </div>

          <div className={`cart-content ${showCart ? "show-cart" : ""}`}>
            {showCart && <CartComponent onClose={handleCloseCart} />}
          </div>

          {showCart && (
            <div className="cart-backdrop" onClick={handleCartClick}></div>
          )}
        </div>
        <div className="profile">
          <Profile />
        </div>
      </div>
    </>
  );
};

export default Header;
