import React, { useEffect, useState } from "react";
import "./logo.scss";

import CONSTANTS from "../../constant";

const Logo = () => {
  const handleLogoClick = () => {
    window.location.href = "/main-page";
  };
  // const getLogo = async () => {
  // //   try {
  // //     const logo = await generalSettings();
  // //     console.log(logo, "logooo");
  // //     setLogo(logo.siteSettings.sitelogo.logoUrl);
  // //   } catch (error) {
  // //     throw error;
  // //   }
  // // };
  // useEffect(() => {
  //   getLogo();
  // }, []);

  return (
    <div className="logo">
      <img src={CONSTANTS.LOGO_URL} alt="Sign up" onClick={handleLogoClick} />
    </div>
  );
};

export default Logo;
