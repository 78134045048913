import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProductGrid = ({ product, mode, handleProductClick, hasMore }) => {
  console.log("product", product);
  return (
    <div className="product-container">
      {mode === "grid" ? (
        <div className="product-grid">
          {product?.length === 0 && !hasMore ? (
            <div className="product-notfound">
              <div className="empty-state">
                <img src="./empty.png" alt="No product available" />
                <p>Product not found!</p>
              </div>
            </div>
          ) : (
            product?.map((item) => (
              <div
                key={item?.id}
                className="product-card"
                onClick={() => handleProductClick(item)}
              >
                <LazyLoadImage
                  alt={item?.title || "Product"}
                  title={item?.title || "Product"}
                  src={item?.thumbnail}
                  placeholderSrc="./muthu_ico.png"
                />
              </div>
            ))
          )}
        </div>
      ) : (
        <div className="product-list">
          {product.length === 0 && !hasMore ? (
            <div className="empty-state">
              <img src="./empty.png" alt="No product available" />
              <p>Product not found!</p>
            </div>
          ) : (
            product.map((item) => (
              <div
                key={item?.id}
                className="product-name-box"
                onClick={() => handleProductClick(item)}
              >
                {item?.title}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default ProductGrid;
