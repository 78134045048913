// import React from "react";

// const Sidebar = ({
//   isOpen,
//   categories,
//   brands,
//   selectedCategories,
//   selectedBrands,
//   onCategoryChange,
//   onBrandChange,
// }) => {
//   console.log(selectedCategories, "hello");

//   return (
//     <div className={`sidebar ${isOpen ? "open" : ""}`}>
//       <div className="sidebar-content">
//         {/* Categories Section */}
//         <div className="sidebar-container categories-container">
//           <h2>Categories</h2>
//           <ul className="category-list">
//             {categories.map((category) => (
//               <li key={category.id}>
//                 <input
//                   type="checkbox"
//                   value={category.name}
//                   onChange={onCategoryChange}
//                   checked={selectedCategories.includes(category.name)}
//                 />
//                 {category.name}
//               </li>
//             ))}
//           </ul>
//         </div>

//         <div className="gap"></div>

//         {/* Brands Section */}
//         <div className="sidebar-container brands-container">
//           <h2>Brands</h2>
//           <ul className="brand-list">
//             {brands.map((brand) => (
//               <li key={brand.id}>
//                 <input
//                   type="checkbox"
//                   value={brand.name}
//                   onChange={onBrandChange}
//                   checked={selectedBrands.includes(brand.name)}
//                 />
//                 {brand.name}
//               </li>
//             ))}
//           </ul>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Sidebar;
// import React from "react";
// import "./Sidebar.scss";
// import { IoClose } from "react-icons/io5";
// const Sidebar = ({
//   isOpen,
//   categories,
//   brands,
//   selectedCategories,
//   selectedBrands,
//   onCategoryChange,
//   onBrandChange,
// }) => {
//   // console.log(selectedCategories, "sidebar cat");

//   return (
//     <>
//       <div className={`sidebar ${isOpen ? "open" : ""}`}>
//         <div className="sidebar-content">
//           <button className="close-btn">
//             <IoClose />
//           </button>
//           <div className="sidebar-container">
//             <div className="categories-container">
//               <h3> Categories</h3>
//               <div className="category-list">
//                 {categories.map((category) => (
//                   <label key={category.id}>
//                     <input
//                       type="checkbox"
//                       value={category.id}
//                       checked={selectedCategories.includes(
//                         category.id.toString()
//                       )}
//                       onChange={onCategoryChange}
//                     />
//                     {category.name}
//                   </label>
//                 ))}
//               </div>
//             </div>
//             <div className="brands-container">
//               <h3>Brands</h3>
//               <div className="brand-list">
//                 {brands.map((brand) => (
//                   <label key={brand.id}>
//                     <input
//                       type="checkbox"
//                       value={brand.id}
//                       checked={selectedBrands.includes(brand.id.toString())}
//                       onChange={onBrandChange}
//                     />
//                     {brand.name}
//                   </label>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
// export default Sidebar;
import React, { useEffect, useRef } from "react";
import "./Sidebar.scss";
import { IoClose } from "react-icons/io5";

// Forward ref to the sidebar component
const Sidebar = React.forwardRef(
  (
    {
      isOpen,
      categories,
      brands,
      selectedCategories,
      selectedBrands,
      onCategoryChange,
      onBrandChange,
      onClose,
    },
    ref
  ) => {
    // const url = new URL(window.location.href); // Get the current URL
    // let searchQuery = url.searchParams.get("");
    // searchQuery = url.searchParams.get("search");
    // console.log("insidesidebar", searchQuery);
    // useEffect(() => {
    //   console.log("asdaadjsndjs");
    // }, [selectedBrands, selectedCategories]);

    useEffect(() => {
      const url = new URL(window.location.href);
      if (selectedCategories.length > 0 || selectedBrands.length > 0) {
        if (url.searchParams.has("search")) {
          url.searchParams.delete("search"); // Remove the "search" parameter
          window.history.replaceState({}, document.title, url.toString()); // Update the URL without reloading
          console.log("Search query cleared");
        }
        console.log("Search query cleared");
      }
    }, [selectedBrands, selectedCategories]);

    return (
      <div className={`sidebar ${isOpen ? "open" : ""}`} ref={ref}>
        <div className="sidebar-content">
          <div className="sidebar-container">
            <button className="close-btn" onClick={onClose}>
              <IoClose />
            </button>
            <div className="categories-container">
              <p className="car-title">Categories</p>
              <div className="category-list">
                {categories.map((category) => (
                  <label key={category.id}>
                    <input
                      type="checkbox"
                      value={category.id}
                      checked={selectedCategories.includes(
                        category.id.toString()
                      )}
                      onChange={onCategoryChange}
                    />
                    {category.name}
                  </label>
                ))}
              </div>
            </div>
            <div className="brands-container">
              <p className="car-title">Brands</p>
              <div className="brand-list">
                {brands.map((brand) => (
                  <label key={brand.id}>
                    <input
                      type="checkbox"
                      value={brand.id}
                      checked={selectedBrands.includes(brand.id.toString())}
                      onChange={onBrandChange}
                    />
                    {brand.name}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default Sidebar;
