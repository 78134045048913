import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PaymentCounts from "./PayementCount";
import { FaCalendarAlt } from "react-icons/fa";

const SearchForm = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  onclick,
  searchkey,
  setSearchKey,
  onclicking,
  cod,
  credit,
  upi,
  searchProductkey,
  setSearchProductKey,
}) => {
  // Set current date if startDate and endDate are initially null
  const CalendarIcon = ({ onClick }) => (
    <FaCalendarAlt className="date-picker-icon" onClick={onClick} />
  );
  const [isStartDatePickerVisible, setIsStartDatePickerVisible] =
    useState(false);
  const [isEndDatePickerVisible, setIsEndDatePickerVisible] = useState(false);
  const currentDate = new Date();

  const toggleStartDatePicker = () =>
    setIsStartDatePickerVisible(!isStartDatePickerVisible);
  const toggleEndDatePicker = () =>
    setIsEndDatePickerVisible(!isEndDatePickerVisible);
  const handleProductInputChange = (e) => {
    setSearchProductKey(e.target.value); // Update the searchText state in the parent
  };
  const handleUserInputChange = (e) => {
    setSearchKey(e.target.value); // Update the searchText state in the parent
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    onclick(); // Trigger the search
  };

  const Handleclear = () => {
    setSearchKey("");
    setStartDate(null);
    setEndDate(null);
    onclicking();
  };

  return (
    <div className="search-form">
      <div className="sales-title">Sales Report</div>
      <form onSubmit={handleSubmit}>
        <div className="search-form-container">
          <div className="leftside-search">
            <div className="search-fields">
              <div className="input-group">
                <label htmlFor="startDate">Start Date</label>
                <div className="date-picker-wrapper">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd/MM/yyyy"
                    maxDate={currentDate}
                    customInput={
                      <div className="custom-date-input">
                        <input
                          type="text"
                          value={
                            startDate ? startDate.toLocaleDateString() : ""
                          }
                          readOnly
                          placeholder="Select Start Date"
                          className="date-textbox"
                        />
                        <FaCalendarAlt className="calendar-icon" />
                      </div>
                    }
                  />
                </div>
              </div>

              <div className="input-group">
                <label htmlFor="endDate">End Date</label>
                <div className="date-picker-wrapper">
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="dd/MM/yyyy"
                    minDate={startDate} // Prevents selecting an end date earlier than start date
                    maxDate={currentDate}
                    customInput={
                      <div className="custom-date-input">
                        <input
                          type="text"
                          value={endDate ? endDate.toLocaleDateString() : ""}
                          readOnly
                          placeholder="Select End Date"
                          className="date-textbox"
                        />
                        <FaCalendarAlt className="calendar-icon" />
                      </div>
                    }
                  />
                </div>
              </div>
            </div>

            {/* Second Row: Search Fields */}
            <div className="row">
              <div className="input-group">
                <input
                  type="text"
                  className="Product-search"
                  id="search"
                  value={searchkey}
                  onChange={handleUserInputChange}
                  placeholder="Search order"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.target.blur();
                      onclick();
                    }
                  }}
                />
              </div>
              <div className="input-group">
                <input
                  type="text"
                  className="Product-search"
                  id="search"
                  value={searchProductkey}
                  onChange={handleProductInputChange}
                  placeholder="Search Product"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.target.blur();
                      onclick();
                    }
                  }}
                />
              </div>
            </div>

            {/* Third Row: Buttons */}
            <div className="row align-right">
              <div className="report-button">
                <button type="submit" onClick={onclick}>
                  Search
                </button>
                <button type="button" onClick={Handleclear}>
                  Clear
                </button>
              </div>
            </div>
          </div>
          <div className="right-side-price-container">
            <PaymentCounts
              // startDate={startDate}
              // endDate={endDate}
              cod={cod}
              credit={credit}
              upi={upi}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchForm;
