import logo from "./logo.svg";
import Mainpage from "./pages/Mainpage/Mainpage";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import SignUp from "./pages/signup/SignUp";
import Register from "./pages/Register/Register";
import Header from "./components/header/Header";
import Reports from "./pages/Reports/Reports";
import Checkout from "./pages/Checkout/Checkout";
import AuthProvider from "./Context/AuthContext";
import SuccessPage from "./pages/Payment/SuccessPage";
import { CartProvider } from "./Context/CartContext";
import ProductDetail from "./components/Product/ProductDetail";
import ProductPage from "./components/Product/Product";
import { useState, useEffect } from "react";
import Profile from "./components/Profile/Profile";
import UpdateProfile from "./components/Profile/UpdateProfile";
import InvoiceDetail from "./components/Payment/InvoiceDetail";
import ProductDetails from "./components/Product/Product";
import ViewOrder from "./components/Report/ViewOrder";
import { apiCheck, generalSettings } from "./services/services";
import CONSTANTS from "./constant";
import MaintenancePage from "./components/MaintenancePage/MaintenancePage";
function App1() {
  return (
    <AuthProvider>
      <Router>
        <CartProvider>
          <App />
        </CartProvider>
      </Router>
    </AuthProvider>
  );
}
function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSiteActive, setIsSiteActive] = useState(true);

  // Hook for checking site status
  useEffect(() => {
    const checkSiteStatus = async () => {
      try {
        const settings = await apiCheck();
        if (settings.status === 200) {
          CONSTANTS.SITE_STATUS = 1;
        }

        setIsSiteActive(CONSTANTS.SITE_STATUS === 1);
      } catch (error) {
        console.error("Error fetching site settings:", error);
        setIsSiteActive(false);
      }
    };

    checkSiteStatus();
  }, []);

  // Hook for navigation
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/main-page");
    }
  }, [location.pathname, navigate]);

  // Conditional rendering based on site status
  // if (!isSiteActive) {
  //   return <MaintenancePage />;
  // }

  generalSettings();

  return (
    <div className="App">
      <Routes>
        <Route path="/sign-in" element={<SignUp />} />
        <Route path="/register" element={<Register />} />
        <Route path="/main-page" element={<Mainpage />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/Reports" element={<Reports />} />
        <Route path="/invoice-page" element={<SuccessPage />} />
        <Route path="/profile" element={<UpdateProfile />} />
        <Route path="/orders/edit/:orderId" element={<ViewOrder />} />
        <Route path="/test" element={<ProductDetails />} />
      </Routes>
    </div>
  );
}

export { App, App1 };
