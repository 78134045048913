// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { toast } from "react-toastify";
// import { API_BASE_URL, createUser } from "../../services/services";
// import { getCookie } from "../../utils/TokenUtils";
// import "./Address.scss";
// import { ToastContainer } from "react-toastify"; // Library for toast messages
// import "react-toastify/dist/ReactToastify.css";

// const AddUserForm = ({ onClose, onUserCreated }) => {
//   let defaultCountry;
//   const defaultFormData = {
//     name: "",
//     lastName: "",
//     phoneNumber: null,
//     address: "",
//     country: "",
//     email: "",
//     zipCode: "",
//   };

//   const [formData, setFormData] = useState(() => {
//     // Retrieve initial data from localStorage
//     const savedFormData = localStorage.getItem("formData");
//     return savedFormData
//       ? JSON.parse(savedFormData)
//       : {
//           name: "",
//           lastName: "",
//           phoneNumber: null,
//           address: "No 14/49, Lakshmipuram Cross St, Lakshmipuram",
//           email: "",
//           country: "",
//           state: "",
//           city: "",
//           zipCode: "",
//         };
//   });

//   const [loading, setLoading] = useState(false);
//   const [countries, setCountries] = useState([]);
//   const [states, setStates] = useState([]);
//   const [cities, setCities] = useState([]);

//   const token = getCookie("accessToken");

//   // Validation patterns
//   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   const phoneRegex = /^[6-9]\d{9}$/;

//   // Fetch countries on component mount
//   useEffect(() => {
//     const fetchCountries = async () => {
//       try {
//         const response = await axios.get(`${API_BASE_URL}/countries`);
//         setCountries(response.data.countries);

//         // Automatically set defaults
//         defaultCountry = response.data.countries.find(
//           (country) => country.name === "India"
//         );
//         if (defaultCountry) {
//           setFormData((prev) => ({
//             ...prev,
//             country: defaultCountry.id,
//           }));
//         }
//       } catch (error) {
//         toast.error("Failed to fetch countries.");
//       }
//     };

//     fetchCountries();
//   }, []);

//   // Fetch states when a country is selected
//   useEffect(() => {
//     if (formData.country) {
//       const fetchStates = async () => {
//         try {
//           const response = await axios.get(
//             `${API_BASE_URL}/countries/${formData.country}/states`
//           );
//           setStates(response.data.states);

//           // Automatically set defaults for Tamil Nadu
//           const defaultState = response.data.states.find(
//             (state) => state.name === "TamilNadu"
//           );
//           console.log(response.data.states, "State");
//           if (defaultState) {
//             setFormData((prev) => ({
//               ...prev,
//               state: defaultState.id,
//             }));
//           }
//         } catch (error) {
//           toast.error("Failed to fetch states.");
//         }
//       };

//       fetchStates();
//     }
//   }, [formData.country]);

//   // Fetch cities when a state is selected
//   useEffect(() => {
//     if (formData.state) {
//       const fetchCities = async () => {
//         try {
//           const response = await axios.get(
//             `${API_BASE_URL}/countries/${formData.country}/states/${formData.state}/cities`
//           );
//           setCities(response.data.cities);

//           // Automatically set defaults for Madurai
//           const defaultCity = response.data.cities.find(
//             (city) => city.name === "Madurai"
//           );
//           if (defaultCity) {
//             setFormData((prev) => ({
//               ...prev,
//               city: defaultCity.id,
//             }));
//           }
//         } catch (error) {
//           toast.error("Failed to fetch cities.");
//         }
//       };

//       fetchCities();
//     }
//   }, [formData.state]);
//   useEffect(() => {
//     localStorage.setItem("formData", JSON.stringify(formData));
//   }, [formData]);

//   // Handle form field changes
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!formData.name) {
//       toast.error("Name is Mandatory");
//       return;
//     }
//     if (
//       !emailRegex.test(formData.email) &&
//       !phoneRegex.test(formData.phoneNumber)
//     ) {
//       toast.error("Fill Phone Number or Email.");
//       return;
//     }

//     // if (!phoneRegex.test(formData.phoneNumber)) {
//     //   toast.error("Please enter a valid 10-digit phone number.");
//     //   return;
//     // }

//     setLoading(true);

//     try {
//       const userInfo = {
//         ...(formData.email != "" && {
//           email: formData.email,
//         }),

//         firstName: formData.name,
//         lastName: formData.lastName,

//         ...(formData.phoneNumber != "" && {
//           phoneNumber: formData.phoneNumber,
//         }),
//       };
//       const address = {
//         firstName: formData.name,
//         lastName: formData.lastName,
//         address: formData.address,
//         cityId: formData.city,
//         stateId: formData.state,
//         zipCode: formData.zipCode,
//         countryId: formData.country,
//         default: true,
//       };
//       const response = await createUser(userInfo, address);
//       console.log(userInfo, "ues");
//       if (response) {
//         toast.success("User Created Successfully");
//         onUserCreated(response); // Pass the new user data to the parent component
//         localStorage.removeItem("formData");
//         onClose();
//       }
//     } catch (error) {
//       console.error("Error saving address:", error.response.data.error);
//       toast.error(error.response.data.error);
//     } finally {
//       setLoading(false);
//     }
//   };
//   const handleClear = async () => {
//     const response = await axios.get(`${API_BASE_URL}/countries`);

//     setFormData(defaultFormData);
//     defaultCountry = response.data.countries.find(
//       (country) => country.name === "India"
//     );
//     if (defaultCountry) {
//       setFormData((prev) => ({
//         ...prev,
//         country: defaultCountry.id,
//       }));
//     }
//     const responsestates = await axios.get(
//       `${API_BASE_URL}/countries/${formData.country}/states`
//     );
//     setStates(responsestates.data.states);

//     // Automatically set defaults for Tamil Nadu
//     const defaultState = responsestates.data.states.find(
//       (state) => state.name === "TamilNadu"
//     );
//     console.log(responsestates.data.states, "State");
//     if (defaultState) {
//       setFormData((prev) => ({
//         ...prev,
//         state: defaultState.id,
//       }));
//     }
//     localStorage.removeItem("formData");
//   };

//   return (
//     <>
//       <div className="address-form-container">
//         <h2>Add New Address</h2>
//         <form onSubmit={handleSubmit}>
//           <div className="form-row">
//             <div className="form-field">
//               <label htmlFor="name">Name</label>
//               <input
//                 type="text"
//                 id="name"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="form-field">
//               <label htmlFor="lastName">Last Name</label>
//               <input
//                 type="text"
//                 id="lastName"
//                 name="lastName"
//                 value={formData.lastName}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//           </div>

//           <div className="form-row">
//             <div className="form-field">
//               <label htmlFor="address">Address</label>
//               <input
//                 type="text"
//                 id="address"
//                 name="address"
//                 value={formData.address}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//           </div>

//           <div className="form-row">
//             <div className="form-field">
//               <label htmlFor="email">Email</label>
//               <input
//                 type="email"
//                 id="email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//             <div className="form-field">
//               <label htmlFor="phoneNumber">Phone Number</label>
//               <input
//                 type="text"
//                 id="phoneNumber"
//                 name="phoneNumber"
//                 value={formData.phoneNumber}
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//           </div>

//           <div className="form-row">
//             <div className="form-field">
//               <label htmlFor="country">Country</label>
//               <select
//                 id="country"
//                 name="country"
//                 value={formData.country}
//                 onChange={handleChange}
//                 required
//               >
//                 <option value="">Select Country</option>
//                 {countries.map((country) => (
//                   <option key={country.id} value={country.id}>
//                     {country.name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className="form-field">
//               <label htmlFor="state">State</label>
//               <select
//                 id="state"
//                 name="state"
//                 value={formData.state}
//                 onChange={handleChange}
//                 required
//                 disabled={!formData.country}
//               >
//                 <option value="">Select State</option>
//                 {states.map((state) => (
//                   <option key={state.id} value={state.id}>
//                     {state.name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </div>

//           <div className="form-row">
//             <div className="form-field">
//               <label htmlFor="city">City</label>
//               <select
//                 id="city"
//                 name="city"
//                 value={formData.city}
//                 onChange={handleChange}
//                 required
//                 disabled={!formData.state}
//               >
//                 <option value="">Select City</option>
//                 {cities.map((city) => (
//                   <option key={city.id} value={city.id}>
//                     {city.name}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className="form-field">
//               <label htmlFor="zipCode">Zip Code</label>
//               <input
//                 type="text"
//                 id="zipCode"
//                 name="zipCode"
//                 value="625001"
//                 onChange={handleChange}
//                 required
//               />
//             </div>
//           </div>
//           <div className="form-actions">
//             <button onClick={handleSubmit} disabled={loading}>
//               {loading ? "Saving..." : "Save"}
//             </button>
//             <button type="button" onClick={handleClear} disabled={loading}>
//               Clear
//             </button>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// };

// export default AddUserForm;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { API_BASE_URL, createUser } from "../../services/services";
// import { getCookie } from "../../utils/TokenUtils";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import "./Address.scss";

// const AddUserForm = ({ onClose, onUserCreated }) => {
//   const [countries, setCountries] = useState([]);
//   const [states, setStates] = useState([]);
//   const [cities, setCities] = useState([]);
//   const [loading, setLoading] = useState(false);

//   const defaultFormData = {
//     name: "",
//     lastName: "",
//     phoneNumber: "",
//     address: "",
//     email: "",
//     country: 1,
//     state: 1,
//     city: 1,
//     zipCode: "625012",
//   };

//   const token = getCookie("accessToken");

//   const fetchCountries = async () => {
//     try {
//       const response = await axios.get(`${API_BASE_URL}/countries`);
//       setCountries(response.data.countries);
//     } catch (error) {
//       console.error("Failed to fetch countries.");
//     }
//   };

//   const fetchStates = async (countryId) => {
//     try {
//       const response = await axios.get(
//         `${API_BASE_URL}/countries/${countryId}/states`
//       );
//       setStates(response.data.states);
//     } catch (error) {
//       console.error("Failed to fetch states.");
//     }
//   };

//   const fetchCities = async (countryId, stateId) => {
//     try {
//       const response = await axios.get(
//         `${API_BASE_URL}/countries/${countryId}/states/${stateId}/cities`
//       );
//       setCities(response.data.cities);
//     } catch (error) {
//       console.error("Failed to fetch cities.");
//     }
//   };

//   const handleClear = (resetForm) => {
//     setStates([]);
//     setCities([]);
//     resetForm();
//   };

//   useEffect(() => {
//     fetchCountries();
//   }, []);

//   const validationSchema = Yup.object({
//     name: Yup.string().required("Name is required"),
//     lastName: Yup.string().required("Last Name is required"),
//     phoneNumber: Yup.string()
//       .matches(/^[6-9]\d{9}$/, "Phone Number must be a valid 10-digit number")
//       .nullable(),
//     email: Yup.string().email("Invalid email address").nullable(),
//     address: Yup.string().required("Address is required"),
//     country: Yup.string().required("Country is required"),
//     state: Yup.string().required("State is required"),
//     city: Yup.string().required("City is required"),
//     zipCode: Yup.string()
//       .matches(/^\d{6}$/, "Zip Code must be a 6-digit number")
//       .required("Zip Code is required"),
//   }).test("email-or-phone", null, function (values) {
//     const { email, phoneNumber } = values;
//     if (!email && !phoneNumber) {
//       return this.createError({
//         path: "email",
//         message: "Email or Phone Number is required",
//       });
//     }
//     return true;
//   });

//   const handleSubmit = async (values, { setSubmitting, resetForm }) => {
//     setLoading(true);
//     try {
//       const userInfo = {
//         ...(values.email && { email: values.email }),
//         firstName: values.name,
//         lastName: values.lastName,
//         ...(values.phoneNumber && { phoneNumber: values.phoneNumber }),
//       };
//       const address = {
//         firstName: values.name,
//         lastName: values.lastName,
//         address: values.address,
//         cityId: values.city,
//         stateId: values.state,
//         zipCode: values.zipCode,
//         countryId: values.country,
//         default: true,
//       };
//       const response = await createUser(userInfo, address);
//       if (response) {
//         onUserCreated(response);
//         onClose();
//         resetForm();
//       }
//     } catch (error) {
//       console.error("Error saving address:", error.response.data.error);
//     } finally {
//       setLoading(false);
//       setSubmitting(false);
//     }
//   };

//   return (
//     <div className="address-form-container">
//       <h2>Add New Address</h2>
//       <Formik
//         initialValues={defaultFormData}
//         validationSchema={validationSchema}
//         onSubmit={handleSubmit}
//       >
//         {({ values, setFieldValue, resetForm, isSubmitting }) => (
//           <Form>
//             <div className="form-row">
//               <div className="form-field">
//                 <label htmlFor="name">Name</label>
//                 <Field type="text" id="name" name="name" />
//                 <ErrorMessage name="name" component="div" className="error" />
//               </div>
//               <div className="form-field">
//                 <label htmlFor="lastName">Last Name</label>
//                 <Field type="text" id="lastName" name="lastName" />
//                 <ErrorMessage
//                   name="lastName"
//                   component="div"
//                   className="error"
//                 />
//               </div>
//             </div>

//             <div className="form-row">
//               <div className="form-field">
//                 <label htmlFor="address">Address</label>
//                 <Field type="text" id="address" name="address" />
//                 <ErrorMessage
//                   name="address"
//                   component="div"
//                   className="error"
//                 />
//               </div>
//             </div>

//             <div className="form-row">
//               <div className="form-field">
//                 <label htmlFor="email">Email</label>
//                 <Field type="email" id="email" name="email" />
//                 <ErrorMessage name="email" component="div" className="error" />
//               </div>
//               <div className="form-field">
//                 <label htmlFor="phoneNumber">Phone Number</label>
//                 <Field type="text" id="phoneNumber" name="phoneNumber" />
//                 <ErrorMessage
//                   name="phoneNumber"
//                   component="div"
//                   className="error"
//                 />
//               </div>
//             </div>

//             <div className="form-row">
//               <div className="form-field">
//                 <label htmlFor="country">Country</label>
//                 <Field
//                   as="select"
//                   id="country"
//                   name="country"
//                   onChange={(e) => {
//                     const value = e.target.value;
//                     setFieldValue("country", value);
//                     setFieldValue("state", "");
//                     setFieldValue("city", "");
//                     fetchStates(value);
//                   }}
//                 >
//                   <option value="">Select Country</option>
//                   {countries.map((country) => (
//                     <option key={country.id} value={country.id}>
//                       {country.name}
//                     </option>
//                   ))}
//                 </Field>
//                 <ErrorMessage
//                   name="country"
//                   component="div"
//                   className="error"
//                 />
//               </div>
//               <div className="form-field">
//                 <label htmlFor="state">State</label>
//                 <Field
//                   as="select"
//                   id="state"
//                   name="state"
//                   onChange={(e) => {
//                     const value = e.target.value;
//                     setFieldValue("state", value);
//                     setFieldValue("city", "");
//                     fetchCities(values.country, value);
//                   }}
//                 >
//                   <option value="">Select State</option>
//                   {states.map((state) => (
//                     <option key={state.id} value={state.id}>
//                       {state.name}
//                     </option>
//                   ))}
//                 </Field>
//                 <ErrorMessage name="state" component="div" className="error" />
//               </div>
//             </div>

//             <div className="form-row">
//               <div className="form-field">
//                 <label htmlFor="city">City</label>
//                 <Field as="select" id="city" name="city">
//                   <option value="">Select City</option>
//                   {cities.map((city) => (
//                     <option key={city.id} value={city.id}>
//                       {city.name}
//                     </option>
//                   ))}
//                 </Field>
//                 <ErrorMessage name="city" component="div" className="error" />
//               </div>
//               <div className="form-field">
//                 <label htmlFor="zipCode">Zip Code</label>
//                 <Field type="text" id="zipCode" name="zipCode" />
//                 <ErrorMessage
//                   name="zipCode"
//                   component="div"
//                   className="error"
//                 />
//               </div>
//             </div>

//             <div className="form-actions">
//               <button type="submit" disabled={isSubmitting || loading}>
//                 {loading ? "Saving..." : "Save"}
//               </button>
//               <button
//                 type="button"
//                 onClick={() => handleClear(resetForm)}
//                 disabled={loading}
//               >
//                 Clear
//               </button>
//             </div>
//           </Form>
//         )}
//       </Formik>
//     </div>
//   );
// };

// export default AddUserForm;
import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL, createUser } from "../../services/services";
import { getCookie } from "../../utils/TokenUtils";
import "./Address.scss";
import { ToastContainer } from "react-toastify"; // Library for toast messages
import "react-toastify/dist/ReactToastify.css";

const AddUserForm = ({ onClose, onUserCreated }) => {
  let defaultCountry;
  const defaultFormData = {
    name: "",
    lastName: "",
    phoneNumber: null,
    address: "No. 14, 49, Lakshmipuram Cross St", // Default value for address
    country: "",
    email: "",
    zipCode: "123456", // Default value for zipCode
  };

  const [formData, setFormData] = useState(() => {
    const savedFormData = localStorage.getItem("formData");
    return savedFormData
      ? JSON.parse(savedFormData)
      : {
          name: "",
          lastName: "",
          phoneNumber: null,
          address: "No. 14, 49, Lakshmipuram Cross St", // Default value for address
          email: "",
          country: "",
          state: "",
          city: "",
          zipCode: "625012", // Default value for zipCode
        };
  });

  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zipCode: "",
  });

  const token = getCookie("accessToken");

  // Validation patterns
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[1-9]\d{9}$/;

  // Fetch countries on component mount
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/countries`);
        setCountries(response.data.countries);

        // Automatically set defaults
        defaultCountry = response.data.countries.find(
          (country) => country.name === "India"
        );
        if (defaultCountry) {
          setFormData((prev) => ({
            ...prev,
            country: defaultCountry.id,
          }));
          setFormData((prev) => ({
            ...prev,
            state: null,
          }));
          setFormData((prev) => ({
            ...prev,
            city: null,
          }));
        }
      } catch (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          country: "Failed to fetch countries.",
        }));
      }
    };

    fetchCountries();
  }, []);

  // Fetch states when a country is selected
  useEffect(() => {
    if (formData.country) {
      const fetchStates = async () => {
        try {
          // setCities("");
          // formData.city = "";
          const response = await axios.get(
            `${API_BASE_URL}/countries/${formData.country}/states`
          );
          setStates(response.data.states);

          // Automatically set defaults for Tamil Nadu
          const defaultState = response.data.states.find(
            (state) => state.name === "TamilNadu"
          );
          if (defaultState) {
            setFormData((prev) => ({
              ...prev,
              state: defaultState.id,
            }));
          }
        } catch (error) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            state: "Failed to fetch states.",
          }));
        }
      };

      fetchStates();
    }
  }, [formData.country]);

  // Fetch cities when a state is selected
  useEffect(() => {
    if (formData.state && formData.country) {
      const fetchCities = async () => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/countries/${formData.country}/states/${formData.state}/cities`
          );
          setCities(response.data.cities);

          // Automatically set defaults for Madurai
          const defaultCity = response.data.cities.find(
            (city) => city.name === "Madurai"
          );
          if (defaultCity) {
            setFormData((prev) => ({
              ...prev,
              city: defaultCity.id,
            }));
          }
        } catch (error) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            city: "Failed to fetch cities.",
          }));
        }
      };

      fetchCities();
    }
  }, [formData.state, formData.country]);

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Clear errors for email and phone number when the user starts typing
    if (name == "phoneNumber") {
      // setErrors({
      //   ...errors,
      //   // [errors.email]: "", // Clears the specific error for email or phoneNumber
      // });
      errors.email = "";
    }
    if (formData.email && !emailRegex.test(formData.email)) {
      // valid = false;
      errors.email = "Please provide a valid email.";
    }
    if (formData.phoneNumber && !phoneRegex.test(formData.phoneNumber)) {
      // valid = false;
      errors.phoneNumber = "Please provide a valid 10-digit phone number.";
    }
    // Update the form data as usual
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "", // Clears the error for the changed field
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let valid = true;
    let newErrors = { ...errors };

    // Validate required fields
    if (!formData.name) {
      valid = false;
      newErrors.name = "Name is required.";
    }
    if (!formData.address) {
      valid = false;
      newErrors.address = "Address is required.";
    }
    // if (!formData.country) {
    //   valid = false;
    //   newErrors.country = "Country is required.";
    // }
    // if (!formData.state) {
    //   valid = false;
    //   newErrors.state = "State is required.";
    // }
    // if (!formData.city) {
    //   valid = false;
    //   newErrors.city = "City is required.";
    // }
    if (formData.zipCode) {
    }
    if (!formData.zipCode || formData.zipCode.length === 0) {
      valid = false;

      newErrors.zipCode = "Zip Code is required.";
    } else if (!/^\d{6}$/.test(formData.zipCode)) {
      valid = false;
      newErrors.zipCode = "Zip Code must be a valid 6-digit number.";
    }

    // Validate phone or email
    if (!formData.phoneNumber && !formData.email) {
      valid = false;
      newErrors.email = "Please provide a valid email or phone number.";
    }
    if (formData.email && !emailRegex.test(formData.email)) {
      valid = false;
      newErrors.email = "Please provide a valid email.";
    }
    if (formData.phoneNumber && !phoneRegex.test(formData.phoneNumber)) {
      valid = false;
      newErrors.phoneNumber = "Please provide a valid 10-digit phone number.";
    }

    setErrors(newErrors);

    if (!valid) {
      return;
    }

    setLoading(true);

    try {
      const userInfo = {
        ...(formData.email != "" && {
          email: formData.email,
        }),
        firstName: formData.name,
        lastName: formData.lastName,
        ...(formData.phoneNumber != "" && {
          phoneNumber: formData.phoneNumber,
        }),
      };

      const address = {
        firstName: formData.name,
        lastName: formData.lastName,
        address: formData.address,
        cityId: formData.city,
        stateId: formData.state,
        zipCode: formData.zipCode,
        countryId: formData.country,
        default: true,
      };

      const response = await createUser(userInfo, address);
      if (response) {
        onUserCreated(response);
        localStorage.removeItem("formData");
        onClose();
      }
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        global: error.response?.data?.error || "Error saving user.",
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleClear = async () => {
    setFormData((prev) => ({
      ...defaultFormData,
      country: prev.country, // Retain the currently selected country
      state: prev.state, // Retain the currently selected state
      city: prev.city, // Retain the currently selected city
    }));
    setErrors({
      name: "",
      emailphone: "",
      address: "",
      country: "",
      state: "",
      city: "",
      zipCode: "",
    });
    localStorage.removeItem("formData");
  };

  return (
    <>
      <div className="address-form-container">
        <h2>Add New Address</h2>
        {errors.global && <div className="error">{errors.global}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-field error">
              <label htmlFor="name">
                Name <span className="mandatory">*</span>
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && (
                <span className="error-message">{errors.name}</span>
              )}
            </div>
            <div className="form-field">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-field error">
              <label htmlFor="address">
                {" "}
                Address <span className="mandatory">*</span>
              </label>
              {/* <input
                type="text"
                id="address"
                className="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
              /> */}
              <textarea
                id="address"
                className="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                style={{
                  height: "80px",
                  width: "95%",
                  maxwidth: "95",
                }}
              ></textarea>
              {errors.address && (
                <span className="error-message">{errors.address}</span>
              )}
            </div>
          </div>
          <div className="form-row">
            <div className="form-field error">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <span className="error-message">{errors.email}</span>
              )}
            </div>
            <div className="form-field error">
              <label htmlFor="phoneNumber">Phone Number</label>
              {errors.phoneNumber && (
                <span className="error-message">{errors.phoneNumber}</span>
              )}
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-field">
              <label htmlFor="country">
                Country <span className="mandatory">*</span>
              </label>
              <select
                id="country"
                name="country"
                value={formData.country}
                onChange={handleChange}
              >
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </select>
              {errors.country && (
                <span className="error-message">{errors.country}</span>
              )}
            </div>
            <div className="form-field">
              <label htmlFor="state">
                State <span className="mandatory">*</span>
              </label>
              <select
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
              >
                <option value="">Select State</option>
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
              </select>
              {errors.state && (
                <span className="error-message">{errors.state}</span>
              )}
            </div>
          </div>

          <div className="form-row">
            <div className="form-field error">
              <label htmlFor="city">
                City <span className="mandatory">*</span>
              </label>
              <select
                id="city"
                name="city"
                value={states ? formData.city : ""}
                onChange={handleChange}
              >
                <option value="">Select City</option>
                {cities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </select>
              {errors.city && (
                <span className="error-message">{errors.city}</span>
              )}
            </div>
            <div className="form-field error">
              <label htmlFor="zipCode">
                Zip Code <span className="mandatory">*</span>
              </label>
              <input
                type="text"
                id="zipCode"
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
              />
              {errors.zipCode && (
                <span className="error-message">{errors.zipCode}</span>
              )}
            </div>
          </div>

          <div className="form-actions">
            <button type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </button>
            <button type="button" onClick={handleClear}>
              Clear
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddUserForm;
