// import React, { useContext, useState, useEffect } from "react";
// import "./Profile.scss";
// import { FaUser } from "react-icons/fa6";
// import { FaSignOutAlt } from "react-icons/fa";
// import UpdateProfile from "./UpdateProfile";
// import { getCookie } from "../../utils/TokenUtils";
// import { AuthContext } from "../../Context/AuthContext";
// import { useNavigate } from "react-router-dom";

// const Profile = () => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const { logout } = useContext(AuthContext);
//   const { isLoggedIn } = useContext(AuthContext);
//   const navigate = useNavigate();
//   useEffect(() => {
//     const accessToken = getCookie("accessToken");
//     if (accessToken) {
//       // navigate("/");
//     } else {
//       navigate("/sign-in");
//     }
//   }, [isLoggedIn, navigate]);

//   const toggleSidebar = () => {
//     setIsSidebarOpen(!isSidebarOpen);
//   };
//   const handleLogout = () => {
//     logout();
//   };
//   return (
//     <div className="profile">
//       <div className="profile-pic">
//         <img src="/profile.png" alt="Profile" onClick={toggleSidebar} />
//       </div>
//       <div className={`sidebar-profile ${isSidebarOpen ? "open" : ""}`}>
//         <div className="icon-section">
//           <div>
//             <FaUser />
//           </div>
//           <div>
//             <a href="">MyProfile</a>
//           </div>
//         </div>
//         <div className="logout-sec">
//           <div>
//             {" "}
//             <FaSignOutAlt />
//           </div>
//           <div>
//             {" "}
//             <button className="logout-btn" onClick={handleLogout}>
//               Logout
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Profile;
import React, { useContext, useState, useEffect, useRef } from "react";
import "./Profile.scss";
import { FaSignOutAlt } from "react-icons/fa";
import { getCookie } from "../../utils/TokenUtils";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { IoLogOutSharp } from "react-icons/io5";

const Profile = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { logout } = useContext(AuthContext);
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const iconRef = useRef(null); // Ref for the profile icon to handle clicks on it

  useEffect(() => {
    const accessToken = getCookie("accessToken");
    if (!accessToken) {
      navigate("/sign-in");
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Ensure the click is outside both the sidebar and the icon
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setIsSidebarOpen(false); // Close the sidebar
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="profile">
      {/* <div ref={iconRef}>
        <CgProfile onClick={toggleSidebar} />
      </div>
      <div
        className={`sidebar-profile ${isSidebarOpen ? "open" : ""}`}
        ref={sidebarRef}
      >
        <div className="icon-section">
          {/* Add any additional content here */}
      {/* </div>
        <div className="logout-sec">
          <div>
            <FaSignOutAlt />
          </div>
          <div>
            <button className="logout-btn" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div> */}
      {/* </div> */}
      {/* } */}
      <div>
        <IoLogOutSharp onClick={handleLogout} />
      </div>
    </div>
  );
};

export default Profile;
