import React, { useContext, useState, useEffect, useCallback } from "react";
import "./Checkoutcomponent.scss";
import Button from "../button/Button";
import { useNavigate } from "react-router-dom";
import "../Cart/Cart.scss";
import AddressBook from "../User/AddUser";
import Modal from "../Modal/Modal";
import { IoAddCircle, IoClose } from "react-icons/io5";
import { CartContext } from "../../Context/CartContext";
import { FaMinus, FaPlus } from "react-icons/fa";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify"; //this library use for toastmessage
import "react-toastify/dist/ReactToastify.css"; //this toast css
import {
  applyCoupon,
  fetchCategories,
  fetchCountries,
  fetchPaymentMethods,
  orderCreation,
  searchUsers,
} from "../../services/services";
import { calculateCartTotals } from "../../utils/cartUtils.js";
import { getCookie } from "../../utils/TokenUtils";
import InvoiceDetail from "../Payment/PaymentSucess.js";
import { AuthContext } from "../../Context/AuthContext.js";
import { use } from "react";
import { BsCheckLg } from "react-icons/bs";
import CONSTANTS from "../../constant.js";
const CheckoutComponent = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { cartItems, dispatch, couponResponse } = useContext(CartContext);
  const [couponName, setCouponName] = useState("");
  const token = getCookie("accessToken");

  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [transactionId, setTransactionId] = useState("");
  const [orderNotes, setOrderNotes] = useState("");
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [paymentMethodChange, setPaymentMethodChange] = useState(1);
  const [searchUser, setSearchUser] = useState("");
  // const [existingUser, setExistingUser] = useState("");
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const { isLoggedIn } = useContext(AuthContext);

  const {
    discount,
    subtotalPrice,
    subtotalWithoutTax,
    gstTotal,
    totalPrice,
    shippingPrice,
  } = calculateCartTotals(cartItems, couponResponse);
  console.log(selectedUser, "selected user");
  const handlePaymentMethodChange = (e) => {
    setPaymentMethodChange(e.target.value);
    setTransactionId("");
  };
  if (cartItems.length === 0) {
    navigate("/main-page");
  }
  const productDetails = cartItems.flatMap((item) => ({
    productId: item.id,
    variantId: item.variant ? item.variant.id : null,
    quantity: item.quantity || 1,
  }));
  useEffect(() => {}, [query]);

  const handleSearchUser = (e) => {
    setResults([]);

    setSearchUser(e.target.value);
    setQuery(e.target.value);
    setUserLoading(true);
    // if (query.length <= 0) {
    //   setUserLoading(false);
    // }
    // setSelectedUser(null);
  };
  useEffect(() => {
    const PaymentMethods = async () => {
      setLoading(true);
      setError(null);
      try {
        const Payments = await fetchPaymentMethods();

        setPaymentMethod(Payments);
      } catch (error) {
        console.error("Fetch users failed:", error);
        setError(error.message || "An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    PaymentMethods();

    return;
  }, []);

  const handleAddUserClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleUpdateQuantity = (productId, variant, newQuantity, stock) => {
    if (variant) {
      if (newQuantity > variant.stock) {
        toast.error("The quantity exceeds available stock.");
        return;
      }
    } else {
      console.log(newQuantity, stock, "ugurui");
      if (newQuantity > stock) {
        toast.error("The quantity exceeds available stock.");
        return;
      }
      if (newQuantity < 1) {
        toast.error("Quantity cannot be less than 1.");
        return;
      }
    }
    dispatch({
      type: "UPDATE_QUANTITY",
      payload: {
        id: productId,
        variant,
        quantity: newQuantity,
      },
    });
  };
  const handleRemoveFromCart = (productId, variant) => {
    dispatch({
      type: "REMOVE_FROM_CART",
      payload: { id: productId, variant },
    });
    toast.success(`Product removed successfully!`);
  };
  const fetchCouponDetails = async () => {
    try {
      if (!couponName) {
        toast.error("Please Enter Coupon Code");
        return;
      }
      const response = await applyCoupon(couponName);
      if (subtotalPrice < response.minSpend) {
        toast.error(
          `Minimum spend of ${CONSTANTS.CURRENCY_SYMBOL}${response.minSpend} required to apply this coupon!`
        );
        return;
      }
      if (response.userBased) {
        console.log(selectedUser.id, response.userId, "couponnn");

        // Ensure `userId` is an array
        if (!Array.isArray(response.userId)) {
          console.error(
            "couponResponse.userId is not an array",
            response.userId
          );
          toast.error("There is an issue with the coupon configuration.");
          return;
        }

        // Convert `selectedUser.id` to a number for consistent comparison
        const userId = Number(selectedUser.id);

        // Check if `userId` is included in the `couponResponse.userId` array
        if (!response.userId.includes(userId)) {
          console.log("couponnn nnnn");
          toast.error("This coupon is invalid or expired for your account!");
          dispatch({ type: "CLEAR_COUPON" });
          setCouponName("");
          return;
        }
      }

      dispatch({ type: "SET_COUPON", payload: response });
      console.log(response, "coupon");

      toast.success(`Applied coupon ${couponName} successfully!`);
    } catch (error) {
      console.log(error, "error");
      if (error.response?.status === 404) {
        toast.error("This coupon is invalid or expired!");
      }
      if (error.response?.status === 400) {
        toast.error("This coupon is invalid or expired!");
      }
    }
  };

  useEffect(() => {
    console.log("hello useeffect");
    if (couponResponse) {
      console.log("hello inside");

      // Check if the coupon is user-based and validate user ID
      if (couponResponse.userBased) {
        console.log(selectedUser.id, couponResponse.userId, "couponnn");

        // Ensure `userId` is an array
        if (!Array.isArray(couponResponse.userId)) {
          console.error(
            "couponResponse.userId is not an array",
            couponResponse.userId
          );
          toast.error("There is an issue with the coupon configuration.");
          return;
        }

        // Convert `selectedUser.id` to a number for consistent comparison
        const userId = Number(selectedUser.id);

        // Check if `userId` is included in the `couponResponse.userId` array
        if (!couponResponse.userId.includes(userId)) {
          console.log("couponnn nnnn");
          toast.error("This coupon is invalid or expired for your account!");
          dispatch({ type: "CLEAR_COUPON" });
          setCouponName("");
          return;
        }
      }
      if (subtotalPrice < couponResponse.minSpend) {
        // toast.error(
        //   `Minimum spend of ₹${couponResponse.minSpend} required to apply this coupon!`
        // );
        dispatch({ type: "CLEAR_COUPON" });
        setCouponName("");
        return;
      }

      // toast.success(`Applied coupon ${couponName} successfully!`);
    }
  }, [subtotalPrice, selectedUser]);

  const handleUserSelect = useCallback((user, addresses) => {
    setSelectedUser(user);
    setQuery("");
    setResults([]);
    console.log("insidehandle");
    setSelectedAddress(selectedUser.addresses[0].id);
  }, []);

  const handleClickDiscount = () => {
    fetchCouponDetails();
  };
  const handleRemoveDiscount = () => {
    dispatch({ type: "CLEAR_COUPON" });
    setCouponName("");
    toast.success(`Removed coupon ${couponName} successfully!`);
  };
  useEffect(() => {
    // if (query.trim() === "" || selectedUser) {
    //   // setResults([]);
    //   return;
    // }
    if (query.trim() == "") {
      // setResults([]);
      return;
    }
    const fetchUsers = async () => {
      setUserLoading(true);
      console.log("hii userloading");
      setError(null);
      try {
        console.log("userssssssssssssssss");
        const users = await searchUsers(query, token);
        console.log("userssssssssssssssss done");
        setResults(users.users);
      } catch (error) {
        console.error("Fetch users failed:", error);
        setError(error.message || "An error occurred while fetching data.");
      } finally {
        setUserLoading(false);
      }
    };

    const debounce = setTimeout(() => {
      fetchUsers();
    }, 300);

    return () => clearTimeout(debounce);
  }, [query, selectedUser]);
  const handleSubmit = async () => {
    if (!paymentMethodChange) {
      toast.error("Please select a payment method.");
      return;
    }

    const otherOrderDetailsPos = {
      orderPaymentVia: paymentMethodChange,
      // orderUserAddressId: selectedUser.addresses[0]
      //   ? selectedAddress.addresses[0].id
      //   : null,
      orderUserId: selectedUser.id,
      orderVia: 3,
      ...(couponResponse && { coupon: couponResponse.code }),
      ...(selectedUser.addresses[0] && {
        orderUserAddressId: selectedUser.addresses[0].id,
      }),
      ...(orderNotes && { orderNotes: orderNotes }),
      ...(transactionId && { paymentId: transactionId }),
    };

    const requestData = {
      productDetails,
      otherOrderDetailsPos,
    };

    if (paymentMethodChange != 1 && !transactionId) {
      toast.error(
        "Transaction ID is required for the selected payment method.dsgdfg",
        paymentMethodChange
      );
      return;
    }
    try {
      const order = await orderCreation(requestData);

      const orderSubTotal = order.orderTotal;
      const InvoiceData = order;
      if (order) {
        dispatch({
          type: "CLEAR_CART",
        });

        navigate(`/orders/edit/${order[0].orderId}`);

        toast.success("Order placed successfully!"); // Toast message for success
      } else {
        throw new Error("Order creation failed.");
      }
    } catch (error) {
      console.error("Error placing order:", error.response.data.error);
      toast.error(error.response.data.error); // Toast message for failure
    }
  };

  useEffect(() => {
    const accessToken = getCookie("accessToken");
    if (accessToken) {
      // navigate("/");
    } else {
      navigate("/sign-in");
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    console.log("aaa");
    setQuery("");
    setResults([]);
    if (!selectedUser) return;

    // const fetchUserDetails = async () => {
    //   setLoading(true);
    //   setError(null);
    //   try {
    //     const response = await axios.get(
    //       `${baseURL}/users/${selectedUser.id}`,
    //       {
    //         headers: { Authorization: `Bearer ${token}` },
    //       }
    //     );

    //     onUserSelect(selectedUser, response.data.addresses);
    //     const defaultAddress = response.data.find(
    //       (address) => address.default
    //     );
    //     setUserDetails(response.data);
    //     setUserAddress(defaultAddress);
    //     console.log(defaultAddress);
    //   } catch (err) {
    //     setError("An error occurred while fetching user details.");
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    // fetchUserDetails();
  }, [selectedUser, handleUserSelect]);
  // useEffect(() => {}, [selectedUser]);
  const handleNewUserCreated = (user) => {
    if (!user || Object.keys(user).length === 0) {
      toast.error("User creation failed or empty user data returned.");
      return;
    }

    // if (!user.address.id || !user.address.firstName || !user.address.email) {
    //   toast.error("User data is incomplete.");
    //   return;
    // }

    setSelectedUser(user);
  };

  return (
    <>
      <ToastContainer
        className="toast-container-center"
        position="bottom-right"
      />

      <div className="containers">
        <div className="top-links">
          <a href="/main-page" className="mainpage-link">
            Home
          </a>
          <p>/</p>
          <a href="" className="disable-link">
            checkout
          </a>
        </div>

        <div className="checkout-container">
          <div className="checkout-left-container">
            <div className="responsive-userdetails">
              <div className="customer-details">
                <p className="common-title">Customer Details</p>
                <div className="search-user">
                  <button className="adduser-btn" onClick={handleAddUserClick}>
                    <FaPlus />
                  </button>
                  <input
                    type="search"
                    value={query}
                    // onChange={(e) => setQuery(e.target.value)}
                    onChange={handleSearchUser}
                    placeholder="Search Customer"
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.target.blur(); // Removes focus from the input
                      }
                    }}
                  />
                </div>
                {userLoading && query && <p>Searching...</p>}
                {error && <p>{error}</p>}
                {/* <div className="results"> */}
                <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                  <AddressBook
                    onClose={handleCloseModal}
                    onUserCreated={handleNewUserCreated}
                  />
                </Modal>
                {results && results.length > 0 ? (
                  <div className="results">
                    {results.map((result) => (
                      <div key={result.id} className="user-search">
                        {/* <div
                        className="search-user"
                        onClick={() => setSelectedUser(result)}
                      >
                        <span>{result.firstName}</span>
                      </div> */}
                        <div
                          className="user-details"
                          onClick={() => setSelectedUser(result)}
                        >
                          <label
                            htmlFor={`user-${result.id}`}
                            className="user-info"
                          >
                            <div className="user_name">{result.firstName}</div>
                            <div className="user_email">{result.email}</div>
                            <div className="user_phone">
                              {result.phoneNumber}
                            </div>
                            <div className="user_address">
                              {result.addresses?.[0]?.address ||
                                "No address available"}
                            </div>
                            <div className="user_address">
                              {result.addresses?.[0]?.country?.name || ""}
                            </div>
                            <div className="user_address">
                              {result.addresses?.[0]?.state?.name || ""}
                            </div>
                            <div className="user_address">
                              {result.addresses?.[0]?.city?.name || ""}
                            </div>
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : query.trim() && !userLoading && results.length === 0 ? (
                  <div>No customer found</div>
                ) : null}{" "}
                {/* Closing the .results div before the selected user section */}
                {selectedUser && results.length <= 0 && (
                  <div className="selected-user">
                    {console.log("selected - user", query)}
                    <h4>Customer Information</h4>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Name:</strong>
                          </td>
                          <td>
                            {selectedUser.firstName} {selectedUser.lastName}
                          </td>
                        </tr>
                        {selectedUser.email && (
                          <tr>
                            <td>
                              <strong>Email:</strong>
                            </td>
                            <td>{selectedUser.email}</td>
                          </tr>
                        )}
                        {selectedUser.phoneNumber && (
                          <tr>
                            <td>
                              <strong>Phone:</strong>
                            </td>
                            <td>{selectedUser.phoneNumber}</td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            <strong>Address:</strong>
                          </td>
                          <td>
                            {selectedUser.addresses?.[0]?.address ||
                              "Address Not Found"}
                            ,{selectedUser.addresses?.[0]?.country?.name || ""},
                            {selectedUser.addresses?.[0]?.state?.name || ""},
                            {selectedUser.addresses?.[0]?.city?.name || ""},
                            {selectedUser.addresses?.[0]?.zipCode || ""}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                {/* <div className="user-details">
            <h3>
              <b>User Details</b>
            </h3>
            {userDetails ? (
              <>
                <p>{`${userDetails.firstName} ${userDetails.lastName}`}</p>
                <p>{userDetails.email}</p>
                {userAddress ? (
                  <div>
                    <h3>
                      <b>User Address</b>
                    </h3>
                    <p>{`${userAddress.firstName} ${userAddress.lastName}`}</p>
                    <p>{userAddress.address}</p>
                    <p>{`${userAddress.state?.name || "State not available"}, ${
                      userAddress.city?.name || "City not available"
                    }`}</p>
                    <p>{`${
                      userAddress.country?.name || "Country not available"
                    } - ${userAddress.zipCode}`}</p>
                  </div>
                ) : (
                  <div className="error-list">
                    This user has no address. Please create one.
                  </div>
                )}
              </>
            ) : (
              <div className="error-list">No user details available.</div>
            )}
            <button className="lite-btn" onClick={() => setSelectedUser(null)}>
              Change User
            </button>
          </div> */}
              </div>
            </div>
            {/* Products List Section */}
            <p className="common-title">Order Summary</p>
            <div className="cart-items">
              {cartItems.map((item) => (
                <div
                  className="cart-item"
                  key={`${item.id}-${item.variant?.id || "default"}`}
                >
                  <div className="cart-item-image">
                    <img
                      src={
                        item.images.find(
                          (img) => img.id === item.variant?.imageId
                        )?.thumbnailUrl || item.images[0].thumbnailUrl
                      }
                      alt={item.title}
                    />
                  </div>
                  <div className="cart-item-details">
                    <div className="test">
                      <div className="cart-item-title">
                        <p>{item.title}</p>
                      </div>
                      <div className="cart-item-brand">
                        <p>Brand: {item.brand.name}</p>
                      </div>
                      <div className="cart-item-color">
                        {item.variant?.variantAttributes?.map((attr) => (
                          <p key={attr.id}>
                            {attr.attribute.name}: {attr.attribute_value.name}
                          </p>
                        ))}
                      </div>
                      <div className="cart-item-price">
                        <p>
                          {CONSTANTS.CURRENCY_SYMBOL}
                          {Number(item.variant?.salePrice || item.salePrice) // Ensure it's a number
                            .toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </p>
                      </div>
                    </div>
                    <div className="cart-item-detail">
                      <div className="cart-item-quantity">
                        <button
                          className="decrement"
                          onClick={() =>
                            handleUpdateQuantity(
                              item.id,
                              item.variant,
                              item.quantity - 1,
                              item.stock
                            )
                          }
                          disabled={item.quantity <= 1}
                        >
                          <FaMinus />
                        </button>
                        <span className="quantity-display">
                          {item.quantity}
                        </span>
                        <button
                          className="increment"
                          onClick={() =>
                            handleUpdateQuantity(
                              item.id,
                              item.variant,
                              item.quantity + 1,
                              item.stock
                            )
                          }
                        >
                          <FaPlus />
                        </button>
                      </div>
                      <div className="cart-item-price">
                        <h3>
                          {CONSTANTS.CURRENCY_SYMBOL}
                          {(item.salePrice * item.quantity).toLocaleString(
                            "en-IN",
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                        </h3>
                      </div>
                      <div className="cart-item-remove">
                        <button
                          type="button"
                          onClick={() =>
                            handleRemoveFromCart(item.id, item.variant)
                          }
                        >
                          <IoClose />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Coupon Section */}
            {/* <div className="coupon-section">
              <p className="common-title">Promo Code / Coupon</p>
              <div className="coupon-input">
                <input
                  type="text"
                  value={couponName}
                  onChange={(e) => setCouponName(e.target.value)}
                  readOnly={!!couponResponse}
                /> */}
            {/* <div className="coupon-btn">
                  {couponResponse ? (
                    <button
                      className="remove-btn"
                      onClick={handleRemoveDiscount}
                    >
                      REMOVE
                    </button>
                  ) : (
                    <button onClick={handleClickDiscount}>APPLY </button>
                  )}
                </div>
              </div>
              {couponResponse && (
                <div className="coupon-applied-message">
                  <span className="success-icon">&#10003;</span>
                  <p> Coupon applied successfully!</p>
                </div>
              )}
            </div> */}
            <div className="coupon-section">
              <p className="common-title">Promo Code / Coupon</p>
              {couponResponse ? (
                <div className="coupon-applied">
                  <div>
                    <span className="applied-coupon">
                      <p>
                        {(
                          couponResponse?.code || "Coupon Applied"
                        ).toUpperCase()}
                      </p>
                    </span>
                  </div>
                  <div>
                    <span
                      className="remove-coupon-icon"
                      onClick={handleRemoveDiscount}
                      title="Remove Coupon"
                    >
                      <IoClose />
                    </span>
                  </div>
                </div>
              ) : (
                <div className="coupon-input">
                  <input
                    type="text"
                    placeholder="Enter your coupon code"
                    value={couponName}
                    onChange={(e) => setCouponName(e.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.target.blur();
                        handleClickDiscount(); // Removes focus from the input
                      }
                    }}
                  />
                  <button
                    className={`coupon-btn ${
                      !selectedUser ? "btn-disabled" : ""
                    }`}
                    onClick={handleClickDiscount}
                    disabled={!selectedUser}
                  >
                    APPLY
                  </button>
                </div>
              )}
              {couponResponse && (
                <div className="coupon-applied-message">
                  <span className="success-icon">
                    <BsCheckLg />
                  </span>
                  <p>Coupon applied successfully!</p>
                </div>
              )}
            </div>

            {/* Payment Method Section */}
            <div className="payment-method-section">
              <p className="common-title">Payment Method</p>
              <div className="payment-methods">
                {paymentMethod.map((item) => (
                  <div className="payments-input">
                    <div>
                      <label>
                        <input
                          type="radio"
                          id={item.id}
                          value={item.id}
                          checked={paymentMethodChange == item.id}
                          onChange={handlePaymentMethodChange}
                        />
                      </label>
                    </div>
                    <div>{item.name}</div>
                  </div>
                ))}
              </div>
              {paymentMethodChange != 1 && ( // Replace with your bank transfer ID
                <div className="transaction-id-container">
                  <label htmlFor="transaction-id">Transaction ID:</label>
                  <input
                    type="text"
                    id="transaction-id"
                    name="transaction-id"
                    value={transactionId}
                    onChange={(e) => setTransactionId(e.target.value)}
                    placeholder="Enter transaction ID"
                    required
                  />
                  {!transactionId && (
                    <p className="error-message">
                      Enter your Transaction ID before clicking 'Proceed to
                      Pay'.
                    </p>
                  )}
                </div>
              )}

              <div className="notes-section">
                <h3>Order Notes</h3>
                <textarea
                  onChange={(e) => setOrderNotes(e.target.value)}
                  placeholder="Add any special notes..."
                />
              </div>
            </div>
            <div className="carttotal">
              <div className="amount-left">
                <div>
                  <p>
                    SubTotal:{" "}
                    <span className="amount-right">
                      {CONSTANTS.CURRENCY_SYMBOL}
                      {subtotalPrice.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </p>

                  {gstTotal > 0 && (
                    <p>
                      GST:{" "}
                      <span className="amount-right">
                        {CONSTANTS.CURRENCY_SYMBOL}
                        {gstTotal.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </p>
                  )}
                  {discount > 0 && (
                    <p>
                      Discount:{" "}
                      <span className="amount-right">
                        {CONSTANTS.CURRENCY_SYMBOL}
                        {discount.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </p>
                  )}
                  <p>
                    Grand Total:{" "}
                    <span className="amount-right">
                      {CONSTANTS.CURRENCY_SYMBOL}
                      {totalPrice}
                    </span>
                  </p>
                </div>
              </div>
              <div className="item-quantity">
                <p>
                  (Items: {cartItems.length}, Quantity:{" "}
                  {cartItems.reduce((acc, item) => acc + item.quantity, 0)})
                </p>
              </div>
              <div className="checkout-button-container">
                <Button
                  type="submit"
                  label="Proceed to Pay"
                  className={`btn-primary ${
                    cartItems.length === 0 ||
                    !selectedUser ||
                    !paymentMethodChange
                      ? "btn-disabled"
                      : ""
                  }`}
                  onClick={handleSubmit}
                  disabled={
                    cartItems.length === 0 ||
                    !selectedUser ||
                    !paymentMethodChange
                  }
                />
              </div>
            </div>
          </div>

          {/* Right Container (Customer Details) */}
          <div className="checkout-right-container">
            <div className="customer-details">
              <p className="common-title">Customer Details</p>
              <div className="search-user">
                <button className="adduser-btn" onClick={handleAddUserClick}>
                  <FaPlus /> New Customer
                </button>
                <input
                  type="search"
                  value={query}
                  // onChange={(e) => setQuery(e.target.value)}
                  onChange={handleSearchUser}
                  placeholder="Search Customer"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.target.blur(); // Removes focus from the input
                    }
                  }}
                />
              </div>
              {userLoading && query && <p>Searching...</p>}
              {error && <p>{error}</p>}
              {/* <div className="results"> */}
              <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
                <AddressBook
                  onClose={handleCloseModal}
                  onUserCreated={handleNewUserCreated}
                />
              </Modal>
              {results && results.length > 0 ? (
                <div className="results">
                  {results.map((result) => (
                    <div key={result.id} className="user-search">
                      {/* <div
                        className="search-user"
                        onClick={() => setSelectedUser(result)}
                      >
                        <span>{result.firstName}</span>
                      </div> */}
                      <div
                        className="user-details"
                        onClick={() => setSelectedUser(result)}
                      >
                        <label
                          htmlFor={`user-${result.id}`}
                          className="user-info"
                        >
                          <div className="user_name">{result.firstName}</div>
                          <div className="user_email">{result.email}</div>
                          <div className="user_phone">{result.phoneNumber}</div>
                          <div className="user_address">
                            {result.addresses?.[0]?.address ||
                              "No address available"}
                          </div>
                          <div className="user_address">
                            {result.addresses?.[0]?.country?.name || ""}
                          </div>
                          <div className="user_address">
                            {result.addresses?.[0]?.state?.name || ""}
                          </div>
                          <div className="user_address">
                            {result.addresses?.[0]?.city?.name || ""}
                          </div>
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              ) : query.trim() && !userLoading && results.length === 0 ? (
                <div>No customer found</div>
              ) : null}{" "}
              {/* Closing the .results div before the selected user section */}
              {selectedUser && results.length <= 0 && (
                <div className="selected-user">
                  {console.log("selected - user", query)}
                  <h4>Customer Information</h4>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Name:</strong>
                        </td>
                        <td>
                          {selectedUser.firstName} {selectedUser.lastName}
                        </td>
                      </tr>
                      {selectedUser.email && (
                        <tr>
                          <td>
                            <strong>Email:</strong>
                          </td>
                          <td>{selectedUser.email}</td>
                        </tr>
                      )}
                      {selectedUser.phoneNumber && (
                        <tr>
                          <td>
                            <strong>Phone:</strong>
                          </td>
                          <td>{selectedUser.phoneNumber}</td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <strong>Address:</strong>
                        </td>
                        <td>
                          {selectedUser.addresses?.[0]?.address ||
                            "Address Not Found"}
                          ,{selectedUser.addresses?.[0]?.country?.name || ""},
                          {selectedUser.addresses?.[0]?.state?.name || ""},
                          {selectedUser.addresses?.[0]?.city?.name || ""},
                          {selectedUser.addresses?.[0]?.zipCode || ""}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {/* <div className="user-details">
            <h3>
              <b>User Details</b>
            </h3>
            {userDetails ? (
              <>
                <p>{`${userDetails.firstName} ${userDetails.lastName}`}</p>
                <p>{userDetails.email}</p>
                {userAddress ? (
                  <div>
                    <h3>
                      <b>User Address</b>
                    </h3>
                    <p>{`${userAddress.firstName} ${userAddress.lastName}`}</p>
                    <p>{userAddress.address}</p>
                    <p>{`${userAddress.state?.name || "State not available"}, ${
                      userAddress.city?.name || "City not available"
                    }`}</p>
                    <p>{`${
                      userAddress.country?.name || "Country not available"
                    } - ${userAddress.zipCode}`}</p>
                  </div>
                ) : (
                  <div className="error-list">
                    This user has no address. Please create one.
                  </div>
                )}
              </>
            ) : (
              <div className="error-list">No user details available.</div>
            )}
            <button className="lite-btn" onClick={() => setSelectedUser(null)}>
              Change User
            </button>
          </div> */}
            </div>

            {/* Cart Total Section */}
            <div className="cart-total">
              <div className="amount-left">
                <div>
                  <p>
                    SubTotal:{" "}
                    <span className="amount-right">
                      {CONSTANTS.CURRENCY_SYMBOL}
                      {subtotalPrice.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </p>
                  {gstTotal > 0 && (
                    <p>
                      GST:{" "}
                      <span className="amount-right">
                        {CONSTANTS.CURRENCY_SYMBOL}
                        {gstTotal.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </p>
                  )}
                  {discount > 0 && (
                    <p>
                      Discount:{" "}
                      <span className="amount-right">
                        {CONSTANTS.CURRENCY_SYMBOL}
                        {discount.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </p>
                  )}

                  <p>
                    Grand Total:{" "}
                    <span className="amount-right">
                      {CONSTANTS.CURRENCY_SYMBOL}
                      {totalPrice.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  </p>
                </div>
              </div>
              <div className="item-quantity">
                <p>
                  (Items: {cartItems.length}, Quantity:{" "}
                  {cartItems.reduce((acc, item) => acc + item.quantity, 0)})
                </p>
              </div>
              <div className="checkout-button-container">
                {/* <Button
                  type="submit"
                  label="Proceed to Pay"
                  className={`btn-primary ${
                    cartItems.length === 0 ||
                    !selectedUser ||
                    !paymentMethodChange
                      ? "btn-disabled"
                      : ""
                  }`}
                  onClick={handleSubmit}
                  disabled={
                    cartItems.length === 0 ||
                    !selectedUser ||
                    !paymentMethodChange
                  }
                /> */}
                <Button
                  type="submit"
                  label="Proceed to Pay"
                  className={`btn-primary ${
                    cartItems.length === 0 ||
                    !selectedUser ||
                    !paymentMethodChange ||
                    (paymentMethodChange !== 1 && !transactionId) // Check if transactionId is required for bank transfer
                      ? "btn-disabled"
                      : ""
                  }`}
                  onClick={handleSubmit}
                  disabled={
                    cartItems.length === 0 ||
                    !selectedUser ||
                    !paymentMethodChange ||
                    (paymentMethodChange !== 1 && !transactionId) // Disable if transaction ID is missing for bank transfer
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutComponent;
