import React, { useState, useEffect, useContext } from "react";
import SearchForm from "../../components/Report/SearchForm";
import PaymentCounts from "../../components/Report/PayementCount";
import ReportTable from "../../components/Report/ReportTable";
import "../../components/Report/Report.scss";
import Header from "../../components/header/Header";
import ProductTable from "../../components/Report/ReportTable";
import { getInvoiceByID, reportsByDate } from "../../services/services";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import { getCookie } from "../../utils/TokenUtils";
import ClipLoader from "react-spinners/ClipLoader";
const TruncatedText = ({ text, maxLength = 50 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleToggle = () => setIsExpanded(!isExpanded);

  const displayText =
    !isExpanded && text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;

  return (
    <div className="tr-width">
      {/* Render the truncated or full text with HTML support */}
      <span
        dangerouslySetInnerHTML={{
          __html: displayText,
        }}
      />
      {text.length > maxLength && (
        <button
          onClick={handleToggle}
          style={{
            marginLeft: "5px",
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
            background: "none",
            border: "none",
            padding: "0",
          }}
        >
          {isExpanded ? "...hide" : "...show"}
        </button>
      )}
    </div>
  );
};

function Report() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);
  const [orders, setOrders] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [ordersCounts, setSetOrderCounts] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchkey, setSearchKey] = useState();
  const [searchProductkey, setSearchProductKey] = useState();
  const [codtotal, setCodTotal] = useState();
  const [upitotal, setupiTotal] = useState();
  const [credittotal, setCreditTotal] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);

  const paymentTypeBody = (rowData) =>
    rowData.orderPaymentVia === 1
      ? "COD"
      : rowData.orderPaymentVia === 5
      ? "UPI"
      : rowData.orderPaymentVia === 6
      ? "Card"
      : "";

  useEffect(() => {
    const accessToken = getCookie("accessToken");
    if (accessToken) {
      // navigate("/");
    } else {
      navigate("/sign-in");
    }
  }, [isLoggedIn, navigate]);

  const columns = [
    { header: "ID", body: (rowData) => rowData.orderId },
    {
      field: "createdAt",
      header: "Date",
      body: (rowData) => {
        const formattedDate = new Date(rowData.createdAt).toLocaleDateString();
        return <span>{formattedDate}</span>;
      },
    },
    {
      header: "Title",
      body: (rowData) => {
        const titles = rowData.orderproducts
          .map((product) => product.product.title)
          .join("<br />"); // Add line breaks with <br />
        return <TruncatedText text={titles} maxLength={50} />; // Limit to 100 characters
      },
    },
    {
      header: "User",
      body: (rowData) => {
        // Safely access user details and insert <br /> for line breaks
        const titles = `
      ${rowData.user?.firstName ? rowData.user.firstName + "<br />" : ""}
      ${rowData.user?.email ? rowData.user.email + "<br />" : ""}
      ${
        rowData.orderUserAddress?.address
          ? rowData.orderUserAddress.address + "<br />"
          : ""
      }
      ${
        rowData.orderUserAddress?.country?.name
          ? rowData.orderUserAddress.country.name + "<br />"
          : ""
      }
      ${
        rowData.orderUserAddress?.state?.name
          ? rowData.orderUserAddress.state.name + "<br />"
          : ""
      }
      ${
        rowData.orderUserAddress?.city?.name
          ? rowData.orderUserAddress.city.name + "<br />"
          : ""
      }
      ${
        rowData.orderUserAddress?.zipCode
          ? rowData.orderUserAddress.zipCode + "<br />"
          : ""
      }
    `;

        // Pass the constructed HTML string to the TruncatedText component
        return <TruncatedText text={titles} />;
      },
    },
    { header: "Payment Mode", body: (rowData) => paymentTypeBody(rowData) },
    {
      field: "orderTotal",
      header: "Total",
      body: (rowData) => {
        // Format the order total with commas and 2 decimal places
        return rowData.orderTotal
          ? Number(rowData.orderTotal)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
          : "0.00";
      },
    },
  ];
  const columnexcel = [
    { header: "OrderID", body: (rowData) => rowData.orderId },
    {
      field: "createdAt",
      header: "OrderDate",
      body: (rowData) => {
        const formattedDate = new Date(rowData.createdAt).toLocaleDateString();
        return formattedDate;
      },
    },
    {
      header: "Title",
      body: (rowData) => {
        const titles = rowData.orderproducts
          .map((product) => product.product.title)
          .join(",  ");
        return titles;
      },
    },
    {
      header: "User Details",
      body: (rowData) => {
        const titles = `${rowData.user?.firstName}, ${rowData.user?.email}, ${rowData.UserAddress?.address}, ${rowData.orderUserAddress?.country.name}, ${rowData.orderUserAddress?.state.name}, ${rowData.orderUserAddress?.city.name}`;
        // .map((user) => `${user.firstName} (${user.address})`)
        // .join(", ");
        return titles;
      },
    },
    { header: "Payment Mode", body: (rowData) => paymentTypeBody(rowData) },
    {
      field: "orderTotal",
      header: "Total",
      body: (rowData) => {
        // Format the order total with commas and 2 decimal places
        return rowData.orderTotal
          ? Number(rowData.orderTotal)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")
          : "0.00";
      },
    },
  ];
  useEffect(() => {
    gettingReportsByDate();
  }, []);

  const gettingReportsByDate = async (a, b, c) => {
    setLoading(true);
    try {
      let cod = 0,
        upi = 0,
        credit = 0;

      // Assuming reportsByDate is still fetching the report data
      const data = await reportsByDate(
        startDate,
        endDate,

        searchkey,
        searchProductkey
      );

      setOrders(data.orders.Orders);
      setPaymentData(data);
      setTotalRecords(data.orders.totalOrders);
      const storedOrders = data.orders.Orders;
      if (storedOrders.length) {
        for (let i = 0; i < storedOrders.length; i++) {
          if (storedOrders[i].orderPaymentVia == 1) {
            cod += Number(storedOrders[i].orderTotal);
          }
          if (storedOrders[i].orderPaymentVia == 5) {
            upi += Number(storedOrders[i].orderTotal);
          }
          if (storedOrders[i].orderPaymentVia == 6) {
            credit += Number(storedOrders[i].orderTotal);
          }
        }
      }

      setCodTotal(cod);
      setupiTotal(upi);
      setCreditTotal(credit);
      // setSetOrderCounts(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false
    }
  };
  const gettingReportsByDateOnReset = async (a, b, c) => {
    try {
      let cod = 0,
        upi = 0,
        credit = 0;

      // Assuming reportsByDate is still fetching the report data
      const data = await reportsByDate();

      setOrders(data.orders.Orders);
      setPaymentData(data);
      setTotalRecords(data.orders.totalOrders);
      const storedOrders = data.orders.Orders;
      if (storedOrders.length) {
        for (let i = 0; i < storedOrders.length; i++) {
          if (storedOrders[i].orderPaymentVia == 1) {
            cod += Number(storedOrders[i].orderTotal);
          }
          if (storedOrders[i].orderPaymentVia == 5) {
            upi += Number(storedOrders[i].orderTotal);
          }
          if (storedOrders[i].orderPaymentVia == 6) {
            credit += Number(storedOrders[i].orderTotal);
          }
        }
      }

      setCodTotal(cod);
      setupiTotal(upi);
      setCreditTotal(credit);
      // setSetOrderCounts(data);
    } catch (error) {
      console.error(error);
    }
  };
  const onPageChange = (event) => {
    setPage(event.page + 1);

    setRows(event.rows);
    // gettingReportsByDate();
  };
  const onRowChange = (event) => {
    // setPage(event.page + 1);

    setRows(event.rows);
    // gettingReportsByDate();
  };
  useEffect(() => {}, [rows]);
  // const handleView = (order) => {
  //   navigate(`/orders/edit/${order.orderId}`);
  // };
  const handleView = async (order) => {
    try {
      navigate(`/orders/edit/${order.orderId}`);
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <div className="report-page">
        <div className="header">
          <Header />
        </div>
        <div className="top-links">
          <a href="/main-page" className="mainpage-link">
            Home
          </a>
          <p>/</p>
          <a href="" className="disable-link">
            report
          </a>
        </div>

        <div className="main-container">
          <div className="container">
            <div className="search-section">
              <SearchForm
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                onclick={gettingReportsByDate}
                searchkey={searchkey}
                setSearchKey={setSearchKey}
                onclicking={gettingReportsByDateOnReset}
                cod={codtotal}
                credit={credittotal}
                upi={upitotal}
                searchProductkey={searchProductkey}
                setSearchProductKey={setSearchProductKey}
              />
            </div>

            <div className="payments-section">
              {/* <PaymentCounts
                startDate={startDate}
                endDate={endDate}
                cod={codtotal}
                credit={credittotal}
                upi={upitotal}
              /> */}
            </div>

            <div className="report-section">
              {loading ? (
                <div
                  style={{
                    textAlign: "center",
                    margin: "20px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px", // Ensure loader visibility
                  }}
                >
                  <ClipLoader color="#007bff" loading={loading} size={50} />
                </div>
              ) : (
                <ProductTable
                  data={orders}
                  columns={columns}
                  // onAddNew={handleAddNew}
                  // btnLabel={Constant.btn_addorder}
                  // pageTitle={null}
                  onView={handleView}
                  emptyMessage={
                    <div
                      style={{
                        textAlign: "center",
                        padding: "1rem",
                        fontSize: "16px",
                        color: "#666",
                      }}
                    >
                      No Orders Found
                    </div>
                  }
                  enableView={true}
                  enableDelete={false}
                  // enableAddNew={true}
                  totalRecords={totalRecords}
                  // loading={loading}
                  onPageChange={onPageChange}
                  rows={rows}
                  rowchange={onRowChange}
                  currentPage={page - 1} // PrimeReact uses 0-based index
                  // onSort={handleSort} // Pass sort handler
                  // sortField={sortField} // Pass sort field for table
                  // sortOrder={sortOrder} // Pass sort order for table
                  enableExportPdf={true}
                  enableExportExcel={true}
                  rowsPerPageOptions={[10, 25, 30]}
                  columnexcel={columnexcel} // Use the new prepareExportData function here
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Report;
// import React, { useState, useEffect, useContext } from "react";
// import SearchForm from "../../components/Report/SearchForm";
// import PaymentCounts from "../../components/Report/PayementCount";
// import ReportTable from "../../components/Report/ReportTable";
// import "../../components/Report/Report.scss";
// import Header from "../../components/header/Header";
// import { getInvoiceByID, reportsByDate } from "../../services/services";
// import { useNavigate } from "react-router-dom";
// import { AuthContext } from "../../Context/AuthContext";
// import { getCookie } from "../../utils/TokenUtils";

// // Component for truncating and toggling text
// const TruncatedText = ({ text, maxLength = 30 }) => {
//   const [isExpanded, setIsExpanded] = useState(false);
//   const handleToggle = () => setIsExpanded(!isExpanded);

//   const displayText =
//     !isExpanded && text.length > maxLength
//       ? `${text.substring(0, maxLength)}...`
//       : text;

//   return (
//     <div>
//       <span>{displayText}</span>
//       {text.length > maxLength && (
//         <button
//           onClick={handleToggle}
//           style={{
//             marginLeft: "5px",
//             color: "blue",
//             textDecoration: "underline",
//             cursor: "pointer",
//             background: "none",
//             border: "none",
//             padding: "0",
//           }}
//         >
//           {isExpanded ? "Show Less" : "Show More"}
//         </button>
//       )}
//     </div>
//   );
// };

// function Report() {
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [page, setPage] = useState(1);
//   const [rows, setRows] = useState(10);
//   const [orders, setOrders] = useState([]);
//   const [paymentData, setPaymentData] = useState([]);
//   const [totalRecords, setTotalRecords] = useState(0);
//   const [searchkey, setSearchKey] = useState("");
//   const [codTotal, setCodTotal] = useState(0);
//   const [upiTotal, setUpiTotal] = useState(0);
//   const [creditTotal, setCreditTotal] = useState(0);

//   const navigate = useNavigate();
//   const { isLoggedIn } = useContext(AuthContext);

//   const paymentTypeBody = (rowData) => {
//     switch (rowData.orderPaymentVia) {
//       case 1:
//         return "COD";
//       case 5:
//         return "UPI";
//       case 6:
//         return "Card";
//       default:
//         return "";
//     }
//   };

//   useEffect(() => {
//     const accessToken = getCookie("accessToken");
//     if (!accessToken) {
//       navigate("/sign-in");
//     }
//   }, [isLoggedIn, navigate]);

//   const columns = [
//     { header: "OrderID", body: (rowData) => rowData.orderId },
//     {
//       field: "createdAt",
//       header: "OrderDate",
//       body: (rowData) => {
//         const formattedDate = new Date(rowData.createdAt).toLocaleDateString();
//         return <span>{formattedDate}</span>;
//       },
//     },
//     {
//       header: "Title",
//       body: (rowData) => {
//         const titles = rowData.orderproducts
//           .map((product) => product.product.title)
//           .join(", ");
//         return <TruncatedText text={titles} />;
//       },
//     },
//     { field: "orderTotal", header: "User Details" },
//     { header: "Payment Mode", body: (rowData) => paymentTypeBody(rowData) },
//     { field: "orderTotal", header: "Total" },
//   ];

//   const gettingReportsByDate = async () => {
//     try {
//       let cod = 0,
//         upi = 0,
//         credit = 0;

//       const data = await reportsByDate(startDate, endDate, searchkey);
//       setOrders(data.orders.Orders);
//       setPaymentData(data);
//       setTotalRecords(data.orders.totalOrders);

//       data.orders.Orders.forEach((order) => {
//         switch (order.orderPaymentVia) {
//           case 1:
//             cod += Number(order.orderTotal);
//             break;
//           case 5:
//             upi += Number(order.orderTotal);
//             break;
//           case 6:
//             credit += Number(order.orderTotal);
//             break;
//           default:
//             break;
//         }
//       });

//       setCodTotal(cod);
//       setUpiTotal(upi);
//       setCreditTotal(credit);
//     } catch (error) {
//       console.error("Error fetching reports:", error);
//     }
//   };

//   const onPageChange = (event) => {
//     setPage(event.page + 1);
//     setRows(event.rows);
//   };

//   const handleView = (order) => {
//     navigate(`/orders/edit/${order.orderId}`);
//   };

//   useEffect(() => {
//     gettingReportsByDate();
//   }, [startDate, endDate, searchkey]);

//   return (
//     <div className="report-page">
//       <div className="header">
//         <Header />
//       </div>
//       <div className="top-links">
//         <a href="/main-page" className="mainpage-link">
//           Home
//         </a>
//         <p>/</p>
//         <span className="disable-link">Report</span>
//       </div>
//       <div className="main-container">
//         <div className="container">
//           <div className="search-section">
//             <SearchForm
//               startDate={startDate}
//               setStartDate={setStartDate}
//               endDate={endDate}
//               setEndDate={setEndDate}
//               onclick={gettingReportsByDate}
//               searchkey={searchkey}
//               setSearchKey={setSearchKey}
//             />
//           </div>

//           <div className="payment-section">
//             <PaymentCounts cod={codTotal} credit={creditTotal} upi={upiTotal} />
//           </div>

//           <div className="report-section">
//             <ReportTable
//               data={orders}
//               columns={columns}
//               onView={handleView}
//               emptyMessage="No Orders Found"
//               totalRecords={totalRecords}
//               onPageChange={onPageChange}
//               rows={rows}
//               currentPage={page - 1}
//               rowsPerPageOptions={[10, 25, 50]}
//               enableExportPdf={true}
//               enableExportExcel={true}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Report;
