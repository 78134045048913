import React, { useState } from "react";
import Header from "../../components/header/Header";
import CheckoutComponent from "../../components/Checkout/Checkoutcomponent";
import { ToastContainer } from "react-toastify"; //this library use for toastmessage
import "react-toastify/dist/ReactToastify.css"; //this toast css
const Checkout = () => {
  return (
    <>
      <ToastContainer
        className="toast-container-center"
        position="bottom-right"
      />
      <div className="checkout">
        <div class="header">
          <Header />
        </div>
        <div className="content-container">
          <CheckoutComponent />
        </div>
      </div>
    </>
  );
};

export default Checkout;
