let CONSTANTS = {
  LOGO_URL: "/logo.png",
  LOGO_IMG: "/muthu-logo.png",
  LOGO_TEXT: "/muthu-logo-text.png",
  CURRENCY_CODE: "INR",
  CURRENCY_SYMBOL: "₹",
  CURRENCY_POSITION: 1,
  EXCHANGE_RATE: 1,
  VENDOR_NAME: "MUTHU METALS",
  CONTACT_EMAIL: "muthumetal@gmail.com",
  CONTACT_PHONE: "7465845956",
  CONTACT_ADDRESS: "14/49,Lakshmipuram cross Street,Keelaveli veethi",
  CONTACT_CITY: "Madurai",
  CONTACT_STATE: "TamilNadu",
  CONTACT_COUNTRY: "India",
  CONTACT_ZIPCODE: "625016",
  GST_NO: "12345678",
  TAN_NO: "9876543",
  SITE_STATUS: 0,
};
module.exports = CONSTANTS;
