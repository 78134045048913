import React, { useEffect, useState } from "react";
import { FaCashRegister, FaGooglePay, FaCreditCard } from "react-icons/fa"; // Importing icons
import { paymentTypeCount } from "../../services/services";
import CONSTANTS from "../../constant";

function PaymentCounts({ cod, credit, upi }) {
  const [paymentcount, setPaymentcount] = useState({});

  return (
    <>
      <div className="payment-section">
        {/* Cash Payments */}
        <div className="payment-card">
          <div className="text-container">
            <span className="label">Cash</span>
            <div className="icon-container">
              <img src="/cash.png" alt="cash image" />
            </div>
          </div>
          <span className="count">
            {CONSTANTS.CURRENCY_SYMBOL}
            {cod
              ? cod.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : `0.00`}
          </span>
        </div>

        {/* GPay Payments */}
        <div className="payment-card">
          <div className="text-container">
            <span className="label">Upi</span>
            <div className="icon-container">
              <img src="/gpay.png" alt="cash image" />
            </div>
          </div>

          <span className="count">
            {" "}
            {CONSTANTS.CURRENCY_SYMBOL}
            {upi
              ? upi.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : `0.00`}
          </span>
        </div>

        {/* Credit/Debit Payments */}
        <div className="payment-card">
          <div className="text-container">
            <span className="label">Credit</span>
            <div className="icon-container">
              <img src="/credit.png" alt="cash image" />
            </div>
          </div>
          <span className="count">
            {CONSTANTS.CURRENCY_SYMBOL}
            {credit
              ? credit.toLocaleString("en-IN", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : "0.00"}
          </span>
        </div>
      </div>

      <div className="responsive-paymentcard">
        <div className="payment-card">
          <div className="icon-container">
            <img src="/cash.png" alt="cash image" />
          </div>
          <div className="text-container">
            <span className="count">
              {" "}
              {CONSTANTS.CURRENCY_SYMBOL}
              {cod
                ? cod.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : `0.00`}
            </span>
          </div>
        </div>

        <div className="payment-card">
          <div className="icon-container">
            <img src="/gpay.png" alt="cash image" />
          </div>
          <div className="text-container">
            <span className="count">
              {CONSTANTS.CURRENCY_SYMBOL}
              {upi
                ? upi.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : `0.00`}
            </span>
          </div>
        </div>

        {/* Credit/Debit Payments */}
        <div className="payment-card">
          <div className="icon-container">
            <img src="/credit.png" alt="cash image" />
          </div>
          <div className="text-container">
            <span className="count">
              {CONSTANTS.CURRENCY_SYMBOL}
              {credit
                ? credit.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : `0.00`}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentCounts;
