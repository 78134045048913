import React, { useState, useEffect, useCallback, useContext } from "react";
import "./Product.scss";
import Searchbar from "./Searchbar";
import ProductDetail from "./ProductDetail";
import { IoClose } from "react-icons/io5";
import {
  fetchAllProducts,
  searchProducts,
  searchProductTest,
} from "../../services/services";
import InfiniteScroll from "react-infinite-scroll-component";
import { CartContext } from "../../Context/CartContext";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify"; //this library use for toastmessage
import "react-toastify/dist/ReactToastify.css";
import { FaSearch } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ProductGrid from "./ProductGrid";

const ProductTestComponent = ({
  selectedCategories,
  selectedBrands,
  // changeRe,
  setCategories,
  setBrands,
  mode,
  // displayMode,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [product, setProduct] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { dispatch } = useContext(CartContext);

  useEffect(() => {}, [currentPage]);
  const handleScroll = () => {
    if (hasMore && !isLoading) {
      setCurrentPage((prevPage) => {
        const nextPage = prevPage + 1;
        fetchData(nextPage); // Fetch the next page
        return nextPage;
      });
    }
  };

  const MyImage = ({ image }) => (
    <LazyLoadImage
      alt={image.alt}
      effect="blur"
      wrapperProps={{
        // If you need to, you can tweak the effect transition using the wrapper style.
        style: { transitionDelay: "1s" },
      }}
      src={image.src}
    />
  );
  // Fetch data from the API
  let current = 1;
  const fetchData = useCallback(
    async (page, query) => {
      const url = new URL(window.location.href); // Get the current URL
      let searchQuery = url.searchParams.get("");
      searchQuery = url.searchParams.get("search");
      if (!searchQuery) {
        setSearchQuery("");
      }

      // let fetchedProducts;
      if (isLoading) return; // Prevent redundant calls

      setIsLoading(true);
      try {
        const sortOrder = "asc";
        const sortBy = "title";
        const limit = 30;

        console.log(searchQuery, "bhbbsbdsj");

        // if (searchQuery) {
        //   setCategories([]);
        //   setBrands([]);
        // }
        if (searchQuery) {
          if (selectedCategories.length > 0) {
            setCategories([]);
          }
          if (selectedBrands.length > 0) {
            setBrands([]);
          }
        }
        if (searchQuery?.length > 2) {
          console.log("helos", searchQuery);
          // setCategories([]);
          // setBrands([]);

          // changeRe(searchQuery);
          const fetchedProducts = await searchProductTest(
            page ? page : currentPage,
            "ASC",
            "createdAt",
            [],
            [],
            searchQuery
          );

          if (fetchedProducts.products.length === 0) {
            setHasMore(false); // No more products to fetch
          } else {
            setProduct((prevProducts) => [
              ...prevProducts,
              ...fetchedProducts.products,
            ]);
            setCurrentPage((prevPage) => prevPage + 1); // Increment page for next fetch
          }
        } else {
          const fetchedProducts = await fetchAllProducts(
            page ? page : currentPage,
            sortOrder,
            sortBy,
            selectedCategories,
            selectedBrands
          );

          if (fetchedProducts.products.length === 0) {
            setHasMore(false); // No more products to fetch
          } else {
            setProduct((prevProducts) => [
              ...prevProducts,
              ...fetchedProducts.products,
            ]);
            setCurrentPage((prevPage) => prevPage + 1); // Increment page for next fetch
          }
        }
        // if (fetchedProducts.products.length === 0) {
        //   setHasMore(false); // No more products to fetch
        // } else {
        //   setProduct((prevProducts) => [
        //     ...prevProducts,
        //     ...fetchedProducts.products,
        //   ]);
        //   setCurrentPage((prevPage) => prevPage + 1); // Increment page for next fetch
        // }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, hasMore, selectedCategories, selectedBrands]
  );
  // useEffect(() => {
  //   setCurrentPage(1);
  // }, [selectedCategories, selectedBrands]);
  const resetData = async () => {
    setCurrentPage(1);
    setProduct([]);
    setHasMore(true);
  };
  useEffect(() => {
    const fetchDataWithReset = async () => {
      await resetData();
    };
    fetchDataWithReset();

    setHasMore(true);

    fetchData(1);
  }, [selectedCategories, selectedBrands, searchQuery]);
  // // Fetch data whenever the page changes
  // useEffect(() => {
  //   fetchData();
  // }, [currentPage, fetchData]);
  // Handle product click (modal or add to cart)
  const handleProductClick = (product) => {
    if (product.variants && product.variants.length > 0) {
      setSelectedProduct(product);
    } else {
      if (product.stock == 0) {
        toast.error("Stock Not Available");
        return;
      }
      dispatch({
        type: "ADD_TO_CART",
        payload: {
          productDetails: product,
          quantity: 1,
        },
      });
      handleCloseModal();
      // toast.success("Product Added to Cart Successfully");
    }
  };
  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  const handleSearchScroll = async () => {
    if (searchQuery && hasMore && !isLoading) {
      setIsLoading(true);
      try {
        const limit = 30;
        const searchResults = await searchProducts(
          searchQuery,
          currentPage,
          limit
        );
        setProduct((prevProducts) => [
          ...prevProducts,
          ...(searchResults.products || []),
        ]);
        setHasMore(searchResults.products.length > 0);
        setCurrentPage((prevPage) => prevPage + 1);
      } catch (error) {
        console.error("Error fetching search results:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const url = new URL(window.location);
    if (query) {
      url.searchParams.set("search", query); // Add or update the 'search' query parameter
    } else {
      url.searchParams.delete("search"); // Remove 'search' if query is empty
    }
    window.history.pushState({}, "", url); //
  };
  const handleSearchReset = () => {
    setSearchQuery("");
    fetchData();
  };

  return (
    <>
      <div className="search-bar">
        <Searchbar
          searchQuery={searchQuery}
          handleInputChange={handleInputChange}
        />
      </div>
      <div className="product" id="Product">
        <ProductGrid
          product={product}
          mode={mode}
          handleProductClick={handleProductClick}
          hasMore={hasMore}
        />
        {isLoading && (
          <div className="loader-container">
            <ClipLoader
              color="blue"
              loading={isLoading}
              size={60}
              speedMultiplier={0.5}
            />
          </div>
        )}

        <InfiniteScroll
          dataLength={product.length}
          next={fetchData}
          hasMore={hasMore}
          scrollableTarget="Product"
        >
          {/* Infinite Scroll will trigger next() for loading more data */}
        </InfiniteScroll>
        {selectedProduct && (
          <ProductDetail product={selectedProduct} onClose={handleCloseModal} />
        )}
      </div>
    </>
  );
};
export default ProductTestComponent;
