import React from "react";
import { FaSearch } from "react-icons/fa";

const Searchbar = ({ searchQuery, handleInputChange }) => {
  return (
    <div className="search-bar">
      <input
        className="search-container"
        type="text"
        value={searchQuery}
        onChange={handleInputChange} // Use the passed handleInputChange function
        placeholder="Search"
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            event.target.blur(); // Removes focus from the input
          }
        }}
      />
      <span className="search-icon">
        <FaSearch />
      </span>
    </div>
  );
};

export default Searchbar;
