import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  API_BASE_URL,
  createUser,
  getUserDetails,
  getUSerDetails,
  updateProfile,
} from "../../services/services";
import { getCookie } from "../../utils/TokenUtils";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "./Profile.scss";
import { ToastContainer } from "react-toastify"; // Library for toast messages
import "react-toastify/dist/ReactToastify.css";
import Header from "../header/Header";

const UpdateProfile = () => {
  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const token = getCookie("accessToken");

  // Validation patterns
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[6-9]\d{9}$/;

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const userDetails = async () => {
    const response = await getUserDetails();
  };
  useEffect(() => {
    userDetails();
  }, []);

  const togglePasswordVisibility = (field) => {
    if (field === "oldPassword") {
      setShowOldPassword(!showOldPassword);
    } else if (field === "newPassword") {
      setShowNewPassword(!showNewPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !emailRegex.test(formData.email) &&
      !phoneRegex.test(formData.phoneNumber)
    ) {
      toast.error("Fill Phone Number or Email.");
      return;
    }
    if (!formData.name) {
      toast.error("Name is Mandatory");
    }

    // Validate password match
    if (formData.newPassword !== formData.confirmPassword) {
      toast.error("New password and Confirm password do not match.");
      return;
    }

    setLoading(true);

    try {
      const userInfo = {
        email: formData.email,
        firstName: formData.name,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        oldPassword: formData.oldPassword,
        newPassword: formData.newPassword,
      };
      // const response = await updateProfile(userInfo);
      // if (response) {
      //   toast.success("Profile Updated Successfully");
      // }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="profile-page">
        <div className="header">
          <Header />
        </div>
        <div className="updateProfile">
          <div className="top-links">
            <a href="/main-page" className="mainpage-link">
              Home
            </a>
            <p>/</p>
            <a href="" className="disable-link">
              Profile
            </a>
          </div>
          <div className="profile-form-container">
            <h2>Profile</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-field">
                  <label htmlFor="name">First Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="form-field">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-field">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              {/* Password Fields */}
              <div className="form-row">
                <div className="form-field">
                  <label htmlFor="oldPassword">Old Password</label>
                  <div className="password-inputs">
                    <input
                      type={showOldPassword ? "text" : "password"}
                      id="oldPassword"
                      name="oldPassword"
                      value={formData.oldPassword}
                      onChange={handleChange}
                      required
                    />
                    <span
                      className="toggle-password"
                      onClick={() => togglePasswordVisibility("oldPassword")}
                    >
                      {showOldPassword ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                </div>

                <div className="form-field">
                  <label htmlFor="newPassword">New Password</label>
                  <div className="password-inputs">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      id="newPassword"
                      name="newPassword"
                      value={formData.newPassword}
                      onChange={handleChange}
                      required
                    />
                    <span
                      className="toggle-password"
                      onClick={() => togglePasswordVisibility("newPassword")}
                    >
                      {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="form-field">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <div>
                    <input
                      className="confir-input"
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirmPassword"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      required
                    />
                    <span
                      className="toggle-password"
                      onClick={() => togglePasswordVisibility("newPassword")}
                    >
                      {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </div>
                </div>
              </div>

              <div className="form-actions">
                <div className="cancel-btn">
                  <button onClick={handleSubmit}>Cancel</button>
                </div>
                <div className="submit-btn">
                  <button onClick={handleSubmit}>Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateProfile;
