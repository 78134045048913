// import React, { useEffect, useRef, useState } from "react";
// import PropTypes from "prop-types";

// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import "../../components/Payment/Invoice.scss";
// import "../header/Header.scss";
// import { useParams } from "react-router-dom";
// import { getInvoiceByID } from "../../services/services";
// import Header from "../header/Header";

// const ViewOrder = () => {
//   const [data, setData] = useState();
//   const [formattedDate, setFormatedDate] = useState();
//   useEffect(() => {
//     console.log("hiiidfssdgfd");
//   }, []);

//   const { orderId } = useParams();
//   console.log(orderId, "id order");
//   // const orderData= aysnc () => {
//   //   try{
//   //       const data = await getInvoiceByID(orderId);

//   //   }
//   //   catch(err){
//   //     throw err;
//   //   }
//   // }
//   useEffect(() => {
//     console.log("hey");
//     const fetchOrderData = async () => {
//       try {
//         const data = await getInvoiceByID(orderId);
//         console.log(data, "specific order");
//         setData(data);
//         const date = new Date(data.createdAt).toLocaleDateString();
//         setFormatedDate(date);
//         // Handle data here (if needed).
//       } catch (error) {
//         console.error("Error fetching order data", error);
//       }
//     };
//     fetchOrderData();
//   }, []);

//   // const {
//   //   orderSubTotal,
//   //   orderShippingPrice,
//   //   orderOfferAmount,
//   //   orderTotal,
//   //   orderproducts,
//   //   orderTaxAmount,
//   //   createdAt,
//   //   user,
//   //   address,
//   //   vendor,
//   // } = invoiceData;
//   const invoiceRef = useRef(0);
//   // Example with timestamp
//   // Formats to MM/DD/YYYY (default locale)

//   const renderAddress = (label, address) => (
//     <div>
//       <div className="bill-heading">
//         <p>{label}</p>
//       </div>
//       <p>
//         {address?.firstName} {address?.lastName}
//       </p>
//       <p>{address?.address}</p>
//       <p>{address?.city?.name}</p>
//       <p>{address?.state?.name}</p>
//       <p>{address?.country?.name}</p>
//     </div>
//   );

//   const handleDownloadPDF = () => {
//     const invoiceElement = invoiceRef.current;
//     const buttons = document.querySelectorAll(
//       ".btn-download-pdf,    .btn-print"
//     );
//     const originalStyles = {
//       overflow: invoiceElement.style.overflow,
//       height: invoiceElement.style.height,
//       width: invoiceElement.style.width,
//     };
//     buttons.forEach((button) => (button.style.display = "none"));
//     invoiceElement.style.overflow = "visible";
//     invoiceElement.style.height = "auto"; // Ensure full content is visible
//     invoiceElement.style.width = "595px"; // A4 width in points
//     invoiceElement.style.padding = "20px"; // Optional padding for better spacing

//     const pdfWidth = 595.28; // A4 width in points
//     const pdfHeight = 841.89; // A4 height in points

//     html2canvas(invoiceElement, {
//       scale: 3, // Higher resolution
//       useCORS: true, // Handle cross-origin issues for images/fonts
//     })
//       .then((canvas) => {
//         const imgData = canvas.toDataURL("image/png");

//         const imgWidth = pdfWidth; // Fit content to A4 width
//         const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

//         const doc = new jsPDF("p", "pt", "a4");
//         let yOffset = 0;

//         // Hide unwanted elements

//         while (yOffset < imgHeight) {
//           doc.addImage(imgData, "PNG", 0, -yOffset, imgWidth, imgHeight);
//           yOffset += pdfHeight;

//           if (yOffset < imgHeight) doc.addPage();
//         }

//         doc.save("invoice.pdf");

//         Object.assign(invoiceElement.style, originalStyles);
//       })
//       .catch((error) => {
//         console.error("Error generating PDF:", error);

//         Object.assign(invoiceElement.style, originalStyles);
//       });
//     buttons.forEach((button) => (button.style.display = "block"));
//   };

//   const handlePrint = () => {
//     const invoiceElement = invoiceRef.current;

//     const elementsToHide = invoiceElement.querySelectorAll(
//       ".no-print, .btn-download-pdf, .btn-print, .header"
//     );
//     elementsToHide.forEach((el) => (el.style.display = "none"));

//     const originalStyles = {
//       width: invoiceElement.style.width,
//       margin: invoiceElement.style.margin,
//     };
//     invoiceElement.style.width = "100%";
//     invoiceElement.style.margin = "0";

//     // Trigger print
//     window.print();

//     // Revert styles and visibility after printing
//     elementsToHide.forEach((el) => (el.style.display = "block"));
//     invoiceElement.style.width = originalStyles.width;
//     invoiceElement.style.margin = originalStyles.margin;
//   };

//   return (
//     <>
//       <div className="header">
//         <Header />
//       </div>
//       <div className="success-page-container">
//         <div className="invoice">
//           <div className="no-print">
//             <div className="popup-content" ref={invoiceRef}>
//               <div className="invoice-heading">
//                 <h4>Invoice</h4>
//               </div>
//               <div className="site-content">
//                 <div className="diply-felx">
//                   <img src="/logo.png" alt="Vendor Logo" />
//                 </div>
//                 <div>
//                   <div className="shop-details">
//                     <div>Gst No: {data?.vendor?.gstNumber || "N/A"}</div>
//                     <div>Shop Name: {data?.vendor?.shopName || "N/A"}</div>
//                     <div>Email: {data?.vendor?.email || "N/A"}</div>
//                     <div>Address: {data?.vendor?.address || "N/A"}</div>
//                   </div>
//                   <div className="invocie-details">
//                     <p>Invoice No:{data?.orderId}</p>
//                     <p>Invoice Date:{formattedDate}</p>
//                   </div>
//                 </div>
//               </div>

//               <div>
//                 <div className="diply-felx">
//                   {renderAddress("Bill To Party", data?.address)}
//                   {/* {renderAddress("Shipping Address", address)} */}
//                 </div>
//               </div>
//               <div className="table-container">
//                 <div className="table">
//                   <table>
//                     <thead>
//                       <tr>
//                         <th>id</th>
//                         <th>Title</th>
//                         <th>HSN</th>
//                         <th>GST(%)</th>
//                         <th>₹Price</th>
//                         <th>Quantity</th>
//                         <th>₹SubTotal</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {data?.orderproducts.map((item, index) => (
//                         <tr key={item.id}>
//                           <td>{index + 1}</td>
//                           <td>
//                             <span className="pro-name">
//                               {item.product?.title}
//                               {item.variant?.variantAttributes.map((attr) => (
//                                 <div key={attr.id}>
//                                   {attr.attribute.name}:{" "}
//                                   {attr.attribute_value.name}
//                                 </div>
//                               ))}
//                             </span>
//                           </td>
//                           <td>
//                             {item.product.producttaxes[0]?.hsnmaster.code}
//                           </td>
//                           <td>{item.product.producttaxes[0]?.value}</td>
//                           <td>{item?.salePrice}</td>
//                           <td>{item.quantity}</td>
//                           <td>
//                             {(item?.salePrice * item.quantity).toFixed(2)}
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//               <div className="shipping-cost">
//                 <div className="cost-row">
//                   <span className="label">Sub Total:</span>
//                   <span className="value">
//                     ₹{Number(data?.orderSubTotal).toFixed(2)}
//                   </span>
//                 </div>
//                 <div className="cost-row">
//                   <span className="label">Tax Amount</span>
//                   <span className="value">
//                     ₹{Number(data?.orderTaxAmount).toFixed(2)}
//                   </span>
//                 </div>
//                 <div className="cost-row">
//                   <span className="label">Discount:</span>
//                   <span className="value">
//                     ₹{Number(data?.orderOfferAmount).toFixed(2)}
//                   </span>
//                 </div>
//                 <div className="cost-row total-row">
//                   <span className="label">Total:</span>
//                   <span className="value">
//                     ₹{Number(data?.orderTotal).toFixed(2)}
//                   </span>
//                 </div>
//               </div>

//               <div className="no-pdf no-print">
//                 <btton btnName="close" />
//                 <div class="pdf">
//                   <button
//                     onClick={handleDownloadPDF}
//                     className="btn-download-pdf"
//                   >
//                     Download PDF
//                   </button>
//                   <button onClick={handlePrint} className="btn-print">
//                     Print Invoice
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// // InvoiceDetail.propTypes = {
// //   invoiceData: PropTypes.shape({
// //     orderSubTotal: PropTypes.number.isRequired,
// //     orderShippingPrice: PropTypes.number.isRequired,
// //     orderOfferAmount: PropTypes.number.isRequired,
// //     orderTotal: PropTypes.number.isRequired,
// //     orderproducts: PropTypes.arrayOf(
// //       PropTypes.shape({
// //         id: PropTypes.string.isRequired,
// //         product: PropTypes.shape({
// //           title: PropTypes.string,
// //         }),
// //         variant: PropTypes.shape({
// //           variantAttributes: PropTypes.arrayOf(
// //             PropTypes.shape({
// //               id: PropTypes.string,
// //               attribute: PropTypes.shape({
// //                 name: PropTypes.string,
// //               }),
// //               attribute_value: PropTypes.shape({
// //                 name: PropTypes.string,
// //               }),
// //             })
// //           ),
// //         }),
// //         quantity: PropTypes.number,
// //         salePrice: PropTypes.number,
// //       })
// //     ).isRequired,
// //     user: PropTypes.shape({
// //       firstName: PropTypes.string,
// //       lastName: PropTypes.string,
// //     }),
// //     address: PropTypes.shape({
// //       firstName: PropTypes.string,
// //       lastName: PropTypes.string,
// //       address: PropTypes.string,
// //       city: PropTypes.shape({
// //         name: PropTypes.string,
// //       }),
// //       state: PropTypes.shape({
// //         name: PropTypes.string,
// //       }),
// //       country: PropTypes.shape({
// //         name: PropTypes.string,
// //       }),
// //     }),
// //     vendor: PropTypes.shape({
// //       gstNumber: PropTypes.string,
// //       shopName: PropTypes.string,
// //       email: PropTypes.string,
// //       address: PropTypes.string,
// //     }),
// //   }).isRequired,
// //   handleClosePopup: PropTypes.func.isRequired,
// // };

// export default ViewOrder;
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "../../components/Payment/Invoice.scss";
// import muthu_logo from "../../assets/muthu_logo.png";
import { getCookie } from "../../utils/TokenUtils";
import { API_BASE_URL, getInvoiceByID } from "../../services/services";
import { useParams } from "react-router-dom";
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { IoPrint } from "react-icons/io5";
import { FaDownload } from "react-icons/fa6";
import ClipLoader from "react-spinners/ClipLoader";
import Header from "../header/Header";
import CONSTANTS, { CONTACT_ADDRESS } from "../../constant";

// import LoadingSpinner from "../LoadingSpinners/LoadingSpinner";

const InvoiceDetail = () => {
  const BASE_URL = API_BASE_URL;
  const token = getCookie("accessToken");
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const invoiceRef = useRef();
  const userDetailsFromCookies = getCookie("userDetails")
    ? JSON.parse(getCookie("userDetails"))
    : null;
  const userId = userDetailsFromCookies ? userDetailsFromCookies.id : "";
  const { orderId } = useParams();

  const fetchInvoice = async () => {
    setLoading(true);
    try {
      const response = await getInvoiceByID(orderId);

      setOrderDetails(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchInvoice();
  }, [orderId]);

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };
  console.log("orderdetails", orderDetails);
  const renderAddress = (label, address) => (
    <div className="store-details">
      <h4>{label}</h4>
      <p>
        {orderDetails?.user?.firstName} {orderDetails?.user?.lastName}
      </p>
      <p>{orderDetails?.address?.address}</p>
      <p>{orderDetails?.address?.city?.name}</p>
      <p>{orderDetails?.address?.state?.name}</p>
      <p>{orderDetails?.address?.country?.name}</p>
    </div>
  );
  const handleDownloadPDF = () => {
    const invoiceElement = invoiceRef.current;

    // Save the original styles (if needed for other use)
    const originalStyles = {
      width: invoiceElement.style.width,
      padding: invoiceElement.style.padding,
    };

    // Force desktop-style width and padding
    invoiceElement.style.width = "800px"; // Desktop width
    invoiceElement.style.padding = "20px"; // Desktop padding

    // Hide elements that shouldn't appear in the PDF
    const elementsToHide = invoiceElement.querySelectorAll(".no-pdf");
    elementsToHide.forEach((el) => (el.style.display = "none"));

    // Create PDF from the invoice element
    html2canvas(invoiceElement, { scale: 3 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "a4",
      });

      const imgWidth = doc.internal.pageSize.getWidth();
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = 0;

      // Add the image with appropriate margin
      doc.addImage(imgData, "PNG", 0, position + 20, imgWidth, imgHeight);
      heightLeft -= doc.internal.pageSize.getHeight();

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position + 20, imgWidth, imgHeight);
        heightLeft -= doc.internal.pageSize.getHeight();
      }

      // Save the PDF
      doc.save("invoice.pdf");

      // Restore the original styles
      invoiceElement.style.width = originalStyles.width;
      invoiceElement.style.padding = originalStyles.padding;

      // Show hidden elements again
      elementsToHide.forEach((el) => (el.style.display = ""));
    });
  };
  const handlePrint = () => {
    const invoiceElement = invoiceRef.current;

    // Hide unwanted elements before printing
    const elementsToHide = invoiceElement.querySelectorAll(
      ".no-print, .btn-download-pdf, .btn-print"
    );
    elementsToHide.forEach((el) => (el.style.display = "none"));

    // Trigger print
    window.print();

    // Restore the visibility of hidden elements after printing
    elementsToHide.forEach((el) => (el.style.display = ""));
  };
  // if (loading) {
  //   <ClipLoader />;
  // }
  return (
    <>
      <div className="invoice-page">
        <div className="header">
          <Header />
        </div>
        <div className="invoice">
          {loading ? (
            <ClipLoader />
          ) : (
            <div className="no-print">
              <div class="pdf">
                <button
                  onClick={handleDownloadPDF}
                  className="btn-download-pdf"
                >
                  Download <FaDownload />
                </button>
                <button onClick={handlePrint} className="btn-print">
                  Print <IoPrint />
                </button>
              </div>
              <div className="popups-content" ref={invoiceRef}>
                <h4>Invoice</h4>
                <div className="diply-felx">
                  <img src="/logo.png" alt="Vendor Logo" />{" "}
                  <div className=" store-details">
                    <h4>{CONSTANTS.VENDOR_NAME}</h4>

                    <p>{CONSTANTS.CONTACT_ADDRESS}</p>
                    <p>
                      {CONSTANTS.CONTACT_CITY}-{CONSTANTS.CONTACT_ZIPCODE}
                    </p>
                  </div>
                </div>
                <div className="store-details">
                  <div className="diply-felx">
                    {renderAddress("Billing Address", orderDetails?.address)}
                    <span>{`GST Number : ${CONSTANTS.GST_NO}`}</span>
                  </div>
                </div>
                <div className="invoice-id-detail">
                  <div className="invoice-id-conte">
                    <span>Invoice Number</span>
                    <strong>{orderId}</strong>
                  </div>
                  <div className="invoice-id-conte">
                    <span>Date</span>
                    <strong>{formatDate(orderDetails?.createdAt)}</strong>
                  </div>
                  <div className="invoice-id-conte">
                    <span>Amount</span>
                    <strong>
                      {CONSTANTS.CURRENCY_SYMBOL}
                      {orderDetails?.orderTotal}
                    </strong>
                  </div>
                </div>
                <div className="table-container">
                  <div className="table">
                    <table>
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Title</th>
                          {/* <th>Product</th>{" "}
                        {item.product.producttaxes &&
                        item.product.producttaxes[0] ? (
                          <th>HSN</th>
                        ) : null}
                        {item.product.producttaxes &&
                        item.product.producttaxes[0] ? (
                          <th>GST</th>
                        ) : null} */}
                          <th>HSN</th>
                          <th>GST</th>
                          <th>{CONSTANTS.CURRENCY_SYMBOL}Price</th>
                          <th>Qty</th>
                          <th>{CONSTANTS.CURRENCY_SYMBOL} Subtotal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetails?.orderproducts.map((item, index) => (
                          <tr key={item.id}>
                            <td>{index + 1}</td>
                            <td className="td-pro-name">
                              <span>
                                {item.product?.title}
                                {item.variant?.variantAttributes.map((attr) => (
                                  <div key={attr.id}>
                                    {attr.attribute.name}:{" "}
                                    {attr.attribute_value.name}
                                  </div>
                                ))}
                              </span>
                            </td>
                            <td>
                              {item.product.producttaxes[0]?.hsnmaster.code}
                            </td>
                            <td>
                              {item.product.producttaxes[0]?.hsnmaster.value}
                            </td>

                            <td>
                              {CONSTANTS.CURRENCY_SYMBOL}
                              {item?.salePrice
                                ? Number(item.salePrice).toLocaleString(
                                    "en-IN",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : "0.00"}
                            </td>

                            <td>{item.quantity}</td>
                            <td>
                              {CONSTANTS.CURRENCY_SYMBOL}
                              {Number(
                                item?.salePrice * item.quantity
                              ).toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="shipping-cost">
                    <div>
                      <span>
                        <h4>Subtotal</h4>
                      </span>
                      <span>
                        <h4>
                          {CONSTANTS.CURRENCY_SYMBOL}
                          {orderDetails?.orderSubTotal
                            ? orderDetails.orderSubTotal.toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : "0.00"}
                        </h4>
                      </span>
                    </div>
                    <div>
                      <span>
                        <h4>Tax</h4>
                      </span>
                      <span>
                        <h4>
                          {CONSTANTS.CURRENCY_SYMBOL}
                          {orderDetails?.orderTaxAmount
                            ? Number(orderDetails.orderSubTotal).toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : "0.00"}
                        </h4>
                      </span>
                    </div>
                    {/* <div>
                    <span>
                      <h4>Shipping</h4>
                    </span>
                    <span>
                      <h4>₹{orderDetails?.orderShippingPrice}</h4>
                    </span>
                  </div> */}
                    {orderDetails?.orderOfferAmount && (
                      <div>
                        <span>
                          <h4>Discount</h4>
                        </span>
                        <span>
                          <h4>
                            {CONSTANTS.CURRENCY_SYMBOL}
                            {orderDetails?.orderOfferAmount
                              ? Number(
                                  orderDetails.orderOfferAmount
                                ).toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : "0.00"}
                          </h4>
                        </span>
                      </div>
                    )}

                    <div className="invoice-total">
                      <span>
                        <h4>Total</h4>
                      </span>
                      <span>
                        <h4>
                          {CONSTANTS.CURRENCY_SYMBOL}
                          {orderDetails?.orderTotal
                            ? Number(orderDetails.orderTotal).toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : "0.00"}
                        </h4>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InvoiceDetail;
