// import React, { useEffect, useState } from "react";
// import Header from "../../components/header/Header";
// // import Product from "../../components/Product/Product";
// import Cart from "../../components/Cart/Cart";
// import Sidebar from "../../components/Sidebar/Sidebar";
// import HamburgerMenu from "../../components/HamburgerMenu/HamburgerMenu";
// import "./Mainpage.scss";
// import { fetchBrands, fetchCategories } from "../../services/services";
// import Test from "../../components/Product/Test";

// const Mainpage = () => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);
//   const [selectedCategories, setSelectedCategories] = useState([]); // Selected categories state
//   const [selectedBrands, setSelectedBrands] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [brands, setBrands] = useState([]); // Selected brands state
//   useEffect(() => {
//     getCategories();
//     getBrands();
//   }, []);
//   const getCategories = async () => {
//     try {
//       const categoriesData = await fetchCategories();
//       setCategories(categoriesData);
//     } catch (err) {
//       console.error("Error fetching categories:", err);
//     }
//   };
//   const getBrands = async () => {
//     try {
//       console.log("hiii");
//       const brandsData = await fetchBrands();
//       setBrands(brandsData);
//       console.log(brandsData, "brandsData");
//     } catch (err) {
//       console.error("Error fetching categories:", err);
//     }
//   };

//   const toggleSidebar = () => {
//     setIsSidebarOpen((prevState) => !prevState);
//   };
//   console.log("toggleSidebar in Mainpage:", typeof toggleSidebar);
//   const handleCategoryChange = (event) => {
//     const value = event.target.value;
//     setSelectedCategories((prevState) =>
//       prevState.includes(value)
//         ? prevState.filter((category) => category !== value)
//         : [...prevState, value]
//     );
//   };

//   const handleBrandChange = (event) => {
//     const value = event.target.value;
//     setSelectedBrands((prevState) =>
//       prevState.includes(value)
//         ? prevState.filter((brand) => brand !== value)
//         : [...prevState, value]
//     );
//   };

//   return (
//     <div className="mainpage">
//       <div className="responsive-sidebar">
//         <Sidebar
//           isOpen={isSidebarOpen}
//           categories={categories}
//           brands={brands}
//           selectedCategories={selectedCategories}
//           selectedBrands={selectedBrands}
//           onCategoryChange={handleCategoryChange}
//           onBrandChange={handleBrandChange}
//         />
//       </div>
//       {isSidebarOpen && (
//         <div className="sidebar-overlay" onClick={toggleSidebar}></div>
//       )}

//       <div class="header">
//         <Header onToggle={toggleSidebar} />
//       </div>
//       <div className="content-container">
//         <div className="side-bar">
//           <Sidebar
//             isOpen={isSidebarOpen}
//             categories={categories}
//             brands={brands}
//             selectedCategories={selectedCategories}
//             selectedBrands={selectedBrands}
//             onCategoryChange={handleCategoryChange}
//             onBrandChange={handleBrandChange}
//           />
//         </div>
//         {isSidebarOpen && (
//           <div className="sidebar-overlay" onClick={toggleSidebar}></div>
//         )}

//         <div
//           className={`main-content ${
//             isSidebarOpen ? "with-sidebar" : "without-sidebar"
//           }`}
//         >
//           <Test />
//         </div>

//         <div className="cart-sidebar">
//           <Cart />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Mainpage;
// import React, { useEffect, useRef, useState } from "react";
// import Header from "../../components/header/Header";
// // import Product from "../../components/Product/Product";
// import Cart from "../../components/Cart/Cart";
// import Sidebar from "../../components/Sidebar/Sidebar";
// import HamburgerMenu from "../../components/HamburgerMenu/HamburgerMenu";
// import "./Mainpage.scss";
// import { fetchBrands, fetchCategories } from "../../services/services";
// import Test from "../../components/Product/Test";
// import { ToastContainer } from "react-toastify"; //this library use for toastmessage
// import "react-toastify/dist/ReactToastify.css"; //this toast css
// import { AuthContext } from "../../Context/AuthContext";
// import { useContext } from "react";
// import { Navigate, useNavigate } from "react-router-dom";
// import { getCookie } from "../../utils/TokenUtils";
// const Mainpage = () => {
//   const [isSidebarOpen, setIsSidebarOpen] = useState(true);
//   const [selectedCategories, setSelectedCategories] = useState([]); // Selected categories state
//   const [selectedBrands, setSelectedBrands] = useState([]); // Selected brands state
//   const [categories, setCategories] = useState([]);
//   const [brands, setBrands] = useState([]);

//   const navigate = useNavigate();
//   // if (isLoggedIn) {
//   //   navigate("/main-page");
//   // } else {
//   //   navigate("/sign-in");
//   // }
//   const { isLoggedIn } = useContext(AuthContext);
//   const sidebarRef = useRef(null);
//   useEffect(() => {
//     const accessToken = getCookie("accessToken");
//     if (accessToken) {
//       navigate("/main-page");
//     } else {
//       navigate("/sign-in");
//     }
//   }, [isLoggedIn, navigate]);
//   // const cookie = getCookie("accesstoken");
//   // console.log(cookie, "cookie");
//   // useEffect(() => {
//   //   getCategories();
//   //   getBrands();
//   // }, []);
//   useEffect(() => {
//     const clearQueryParams = () => {
//       const url = new URL(window.location.href);
//       url.search = "";
//       window.history.replaceState({}, document.title, url); // Update the URL without reloading
//     };

//     clearQueryParams(); // Clear query parameters
//     getCategories(); // Fetch categories
//     getBrands(); // Fetch brands
//   }, []);
//   const getCategories = async () => {
//     try {
//       const categoriesData = await fetchCategories();
//       setCategories(categoriesData);
//     } catch (err) {
//       console.error("Error fetching categories:", err);
//     }
//   };

//   const getBrands = async () => {
//     try {
//       const brandsData = await fetchBrands();
//       setBrands(brandsData);
//     } catch (err) {
//       console.error("Error fetching brands:", err);
//     }
//   };

//   const toggleSidebar = () => {
//     setIsSidebarOpen((prevState) => !prevState);
//   };

//   const handleCategoryChange = (event) => {
//     const value = event.target.value;

//     setSelectedCategories((prevState) =>
//       prevState.includes(value)
//         ? prevState.filter((category) => category !== value)
//         : [...prevState, value]
//     );
//     // console.log(selectedCategories, "mainpage");
//   };

//   const handleBrandChange = (event) => {
//     const value = event.target.value;
//     setSelectedBrands((prevState) =>
//       prevState.includes(value)
//         ? prevState.filter((brand) => brand !== value)
//         : [...prevState, value]
//     );
//   };
//   const handleSidebarClose = () => {
//     setIsSidebarOpen(false); // Close the sidebar
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
//         setIsSidebarOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   return (
//     <>
//       <ToastContainer
//         className="toast-container-center"
//         position="bottom-right"
//       />
//       <div className="mainpage">
//         <div className="responsive-sidebar">
//           <Sidebar
//             ref={sidebarRef}
//             isOpen={isSidebarOpen}
//             categories={categories}
//             brands={brands}
//             selectedCategories={selectedCategories}
//             selectedBrands={selectedBrands}
//             onCategoryChange={handleCategoryChange}
//             onBrandChange={handleBrandChange}
//             onClose={handleSidebarClose}
//           />
//         </div>

//         {/* {isSidebarOpen && (
//           <div className="sidebar-overlay" onClick={toggleSidebar}></div>
//         )} */}

//         <div className="header">
//           <Header onToggl={toggleSidebar} />
//         </div>

//         <div className="content-container">
//           <div className="side-bar">
//             <Sidebar
//               isOpen={isSidebarOpen}
//               categories={categories}
//               brands={brands}
//               selectedCategories={selectedCategories}
//               selectedBrands={selectedBrands}
//               onCategoryChange={handleCategoryChange}
//               onBrandChange={handleBrandChange}
//             />
//           </div>

//           {/* {isSidebarOpen && (
//             <div className="sidebar-overlay" onClick={toggleSidebar}></div>
//           )} */}

//           <div
//             className={`main-content ${
//               isSidebarOpen ? "with-sidebar" : "without-sidebar"
//             }`}
//           >
//             <Test
//               selectedCategories={selectedCategories}
//               selectedBrands={selectedBrands}
//             />
//           </div>

//           <div className="cart-sidebar">
//             <Cart />
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Mainpage;
import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/header/Header";
import Cart from "../../components/Cart/Cart";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./Mainpage.scss";
import { fetchBrands, fetchCategories } from "../../services/services";
import Test from "../../components/Product/Test";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../Context/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../utils/TokenUtils";

const Mainpage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar state for mobile
  const [isWebSidebarOpen, setIsWebSidebarOpen] = useState(true); // Sidebar state for web
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [re, setRe] = useState("");
  const [displayMode, setDisplayMode] = useState("grid");
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);
  const sidebarRef = useRef(null); // Ref for sidebar

  // Check screen size and update sidebar state accordingly
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Default to mobile if window width is <= 768

  useEffect(() => {
    const updateScreenSize = () => {
      setIsMobile(window.innerWidth <= 768); // Update state based on window width
    };

    window.addEventListener("resize", updateScreenSize);
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  const changeRe = (query) => {
    setRe(query);
  };
  // Handle user authentication and redirect on page load
  useEffect(() => {
    const accessToken = getCookie("accessToken");
    if (accessToken) {
      navigate("/main-page");
    } else {
      navigate("/sign-in");
    }
  }, [isLoggedIn, navigate]);

  // Fetch categories and brands on load
  useEffect(() => {
    const clearQueryParams = () => {
      const url = new URL(window.location.href);
      url.search = "";
      window.history.replaceState({}, document.title, url);
    };
    clearQueryParams();
    getCategories();
    getBrands();
  }, []);
  const url = new URL(window.location.href); // Get the current URL
  let searchQuery = url.searchParams.get("");
  searchQuery = url.searchParams.get("search");
  console.log("searching", searchQuery);

  useEffect(() => {
    // console.log("searching");
    if (searchQuery) {
      console.log("searching");
      setSelectedCategories([]);
      setSelectedBrands([]);
    }
  }, [re]);
  const getCategories = async () => {
    try {
      const categoriesData = await fetchCategories();
      setCategories(categoriesData);
    } catch (err) {
      console.error("Error fetching categories:", err);
    }
  };

  const getBrands = async () => {
    try {
      const brandsData = await fetchBrands();
      setBrands(brandsData);
    } catch (err) {
      console.error("Error fetching brands:", err);
    }
  };

  const toggleSidebar = () => {
    if (isMobile) {
      setIsSidebarOpen((prevState) => !prevState); // Toggle for mobile view
    } else {
      setIsWebSidebarOpen((prevState) => !prevState); // Toggle for web view
    }
  };

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setSelectedCategories((prevState) =>
      prevState.includes(value)
        ? prevState.filter((category) => category !== value)
        : [...prevState, value]
    );
  };

  const handleBrandChange = (event) => {
    const value = event.target.value;
    setSelectedBrands((prevState) =>
      prevState.includes(value)
        ? prevState.filter((brand) => brand !== value)
        : [...prevState, value]
    );
  };

  const handleSidebarClose = () => {
    setIsSidebarOpen(false); // Close mobile sidebar
  };

  // Close sidebar if click is outside (only for mobile view)
  useEffect(() => {
    if (isMobile) {
      const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setIsSidebarOpen(false); // Close mobile sidebar if clicked outside
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isMobile]);
  const display = () => {
    if (displayMode == "grid") {
      setDisplayMode("list");
    }
    if (displayMode == "list") {
      setDisplayMode("grid");
    }
  };
  return (
    <>
      <ToastContainer
        className="toast-container-center"
        position="bottom-right"
      />
      <div className="mainpage">
        <div className="responsive-sidebar">
          <Sidebar
            ref={sidebarRef}
            isOpen={isSidebarOpen}
            categories={categories}
            brands={brands}
            selectedCategories={selectedCategories}
            selectedBrands={selectedBrands}
            onCategoryChange={handleCategoryChange}
            onBrandChange={handleBrandChange}
            onClose={handleSidebarClose}
            mode={displayMode}
            setDisplayMode={setDisplayMode}
          />
        </div>

        <div className="header">
          <Header
            onToggl={toggleSidebar}
            onclick={display}
            mode={displayMode}
          />
        </div>

        <div className="content-container">
          <div className="side-bar">
            {/* Web sidebar stays open by default */}
            <Sidebar
              isOpen={isWebSidebarOpen}
              categories={categories}
              brands={brands}
              selectedCategories={selectedCategories}
              selectedBrands={selectedBrands}
              onCategoryChange={handleCategoryChange}
              onBrandChange={handleBrandChange}
            />
          </div>

          <div
            className={`main-content ${
              isWebSidebarOpen ? "with-sidebar" : "without-sidebar"
            }`}
          >
            <Test
              selectedCategories={selectedCategories}
              selectedBrands={selectedBrands}
              setCategories={setSelectedCategories}
              setBrands={setSelectedBrands}
              // changeRe={changeRe}
              mode={displayMode}
            />
          </div>

          <div className="cart-sidebar">
            <Cart />
          </div>
        </div>
      </div>
    </>
  );
};

export default Mainpage;
