import React, { useRef } from "react";
import PropTypes from "prop-types";
// import SaveButton from "../../components/Button/SaveButton";
// import Constant from "../../constant/constant";
// import muthu_logo from "../../assets/images/muthu_logo.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./Invoice.scss";
import "../header/Header.scss";
import CONSTANTS from "../../constant";

const InvoiceDetail = ({ invoiceData }) => {
  const {
    orderId,
    orderSubTotal,
    orderShippingPrice,
    orderOfferAmount,
    orderTotal,
    orderproducts,
    orderTaxAmount,
    createdAt,
    user,
    address,
    vendor,
  } = invoiceData;

  const invoiceRef = useRef(); // To capture the invoice content
  // Example with timestamp
  const formattedDate = new Date(createdAt).toLocaleDateString(); // Formats to MM/DD/YYYY (default locale)

  const renderAddress = (label, address) => (
    <div>
      <div className="bill-heading">
        <p>{label}</p>
      </div>
      <p>
        {address?.firstName} {address?.lastName}
      </p>
      <p>{address?.address}</p>
      <p>{address?.city?.name}</p>
      <p>{address?.state?.name}</p>
      <p>{address?.country?.name}</p>
    </div>
  );
  const handleDownloadPDF = () => {
    const invoiceElement = invoiceRef.current;
    const buttons = document.querySelectorAll(
      ".btn-download-pdf,    .btn-print"
    );
    // Save original styles
    const originalStyles = {
      overflow: invoiceElement.style.overflow,
      height: invoiceElement.style.height,
      width: invoiceElement.style.width,
    };
    buttons.forEach((button) => (button.style.display = "none"));
    // Temporarily remove scroll and fit content
    invoiceElement.style.overflow = "visible";
    invoiceElement.style.height = "auto"; // Ensure full content is visible
    invoiceElement.style.width = "595px"; // A4 width in points
    invoiceElement.style.padding = "20px"; // Optional padding for better spacing

    const pdfWidth = 595.28; // A4 width in points
    const pdfHeight = 841.89; // A4 height in points

    html2canvas(invoiceElement, {
      scale: 3, // Higher resolution
      useCORS: true, // Handle cross-origin issues for images/fonts
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");

        const imgWidth = pdfWidth; // Fit content to A4 width
        const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

        const doc = new jsPDF("p", "pt", "a4");
        let yOffset = 0;

        // Add content across multiple pages if it exceeds A4 height
        while (yOffset < imgHeight) {
          doc.addImage(imgData, "PNG", 0, -yOffset, imgWidth, imgHeight);
          yOffset += pdfHeight;

          if (yOffset < imgHeight) doc.addPage();
        }

        doc.save("invoice.pdf");

        // Restore original styles
        Object.assign(invoiceElement.style, originalStyles);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);

        // Restore original styles in case of error
        Object.assign(invoiceElement.style, originalStyles);
      });
  };

  const handlePrint = () => {
    const invoiceElement = invoiceRef.current;

    // Hide unwanted elements before printing
    const elementsToHide = invoiceElement.querySelectorAll(
      ".no-print, .btn-download-pdf, .btn-print, .header"
    );
    elementsToHide.forEach((el) => (el.style.display = "none"));

    // Apply print-specific styles to center and adjust the invoice
    const originalStyles = {
      width: invoiceElement.style.width,
      margin: invoiceElement.style.margin,
    };
    invoiceElement.style.width = "100%";
    invoiceElement.style.margin = "0";

    // Trigger print
    window.print();

    // Revert styles and visibility after printing
    elementsToHide.forEach((el) => (el.style.display = "block"));
    invoiceElement.style.width = originalStyles.width;
    invoiceElement.style.margin = originalStyles.margin;
  };

  return (
    <div className="invoice">
      <div className="no-print">
        <div className="popup-content" ref={invoiceRef}>
          <div className="invoice-heading">
            <h4>Invoice</h4>
          </div>
          <div className="site-content">
            <div className="diply-felx">
              <img src="/logo.png" alt="Vendor Logo" />
            </div>
            <div>
              {/* <div className="shop-details">
                <div>Gst No: {CONSTANTS.GST_NO}</div>
                <div>Shop Name: {CONSTANTS.VENDOR_NAME}</div>
                <div>Email: {vendor?.email || "N/A"}</div>
                <div>Address: {vendor?.address || "N/A"}</div>
              </div> */}
              <div className="invocie-details">
                <p>Invoice No:{orderId}</p>
                <p>Invoice Date:{formattedDate}</p>
              </div>
            </div>
          </div>

          <div>
            <div className="diply-felx">
              {renderAddress("Bill To Party", address)}
              {/* {renderAddress("Shipping Address", address)} */}
            </div>
          </div>
          <div className="table-container">
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th>id</th>
                    <th style={{ width: "100px" }}>Title</th>
                    <th>HSN</th>
                    <th>GST(%)</th>
                    <th>{CONSTANTS.CURRENCY_SYMBOL}Price</th>
                    <th>Quantity</th>
                    <th>{CONSTANTS.CURRENCY_SYMBOL}SubTotal</th>
                  </tr>
                </thead>
                <tbody>
                  {console.log("orderproductssssssss", orderproducts)}
                  {invoiceData.orderproducts.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>
                        <span className="pro-name">
                          {item.product?.title}
                          {item.variant?.variantAttributes.map((attr) => (
                            <div key={attr.id}>
                              {attr.attribute.name}: {attr.attribute_value.name}
                            </div>
                          ))}
                        </span>
                      </td>
                      <td>{item.product.producttaxes[0]?.hsnmaster.code}</td>
                      <td>{item.product.producttaxes[0]?.value}</td>
                      <td>{item?.salePrice}</td>
                      <td>{item.quantity}</td>
                      <td>{(item?.salePrice * item.quantity).toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="shipping-cost">
            <div className="cost-row">
              <span className="label">Sub Total:</span>
              <span className="value">
                {CONSTANTS.CURRENCY_SYMBOL}
                {Number(orderSubTotal).toFixed(2)}
              </span>
            </div>
            <div className="cost-row">
              <span className="label">Tax Amount</span>
              <span className="value">
                {CONSTANTS.CURRENCY_SYMBOL}
                {Number(orderTaxAmount).toFixed(2)}
              </span>
            </div>
            <div className="cost-row">
              <span className="label">Discount:</span>
              <span className="value">
                {CONSTANTS.CURRENCY_SYMBOL}
                {Number(orderOfferAmount).toFixed(2)}
              </span>
            </div>
            <div className="cost-row total-row">
              <span className="label">Total:</span>
              <span className="value">
                {CONSTANTS.CURRENCY_SYMBOL}
                {Number(orderTotal).toFixed(2)}
              </span>
            </div>
          </div>

          <div className="no-pdf no-print">
            <btton btnName="close" />
            <div class="pdf">
              <button onClick={handleDownloadPDF} className="btn-download-pdf">
                Download PDF
              </button>

              <button onClick={handlePrint} className="btn-print">
                Print Invoice
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

InvoiceDetail.propTypes = {
  invoiceData: PropTypes.shape({
    orderSubTotal: PropTypes.number.isRequired,
    orderShippingPrice: PropTypes.number.isRequired,
    orderOfferAmount: PropTypes.number.isRequired,
    orderTotal: PropTypes.number.isRequired,
    orderproducts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        product: PropTypes.shape({
          title: PropTypes.string,
        }),
        variant: PropTypes.shape({
          variantAttributes: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              attribute: PropTypes.shape({
                name: PropTypes.string,
              }),
              attribute_value: PropTypes.shape({
                name: PropTypes.string,
              }),
            })
          ),
        }),
        quantity: PropTypes.number,
        salePrice: PropTypes.number,
      })
    ).isRequired,
    user: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    address: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      address: PropTypes.string,
      city: PropTypes.shape({
        name: PropTypes.string,
      }),
      state: PropTypes.shape({
        name: PropTypes.string,
      }),
      country: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
    vendor: PropTypes.shape({
      gstNumber: PropTypes.string,
      shopName: PropTypes.string,
      email: PropTypes.string,
      address: PropTypes.string,
    }),
  }).isRequired,
  handleClosePopup: PropTypes.func.isRequired,
};

export default InvoiceDetail;
